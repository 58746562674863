import React, { useEffect } from 'react';
import imgbc from '../img/advisorybk/taxhub-advisory-bookkeeping.webp';
import whychoose from '../img/advisorybk/taxhub-why-use-taxhub-bookkeeping.webp';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { FaCheck } from 'react-icons/fa';

const DigitalMarketingService = () => {

    useEffect(() => {

        Aos.init({ duration: 1500 });

        $(document).ready(function () {
            $(this).scrollTop(0);
        });

    });

    return (
        <>
            <section className="bg-black">
                <div className="px-2 bg-white h-full py-16">
                    <div className="container mx-auto block h-full">
                        <div className="flex flex-col-reverse md:flex-row justify-center items-center h-full">
                            <div className="w-full md:w-1/2">
                                <h2
                                    data-aos="fade-right"
                                    className="text-greenn text-3xl sm:text-4xl md:text-[2.6rem] lg:text-4xl xl:text-5xl font-semibold mb-6 centurygothic mt-8 md:mt-0"
                                >
                                    Your Path to Online Success
                                </h2>
                                <p
                                    data-aos="fade-up"
                                    className="text-greens bitter mb-8 font-semibold mt-2 text-xl sm:text-lg domine text-justify"
                                >
                                    In the fast-paced world of digital business, a robust online presence is non-negotiable. But merely having a website or social media accounts won't cut it. To stand out, reach your target audience, and achieve your business goals, you need an effective digital marketing strategy. At Advilite, we're here to be your strategic partner in the digital realm.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2">
                                <img
                                    src={imgbc}
                                    alt="advilite-business-consultancy"
                                    className="mx-auto block w-3/4 rounded-xl"
                                    data-aos="zoom-in-up"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-16 gradienthalf">
                <div className="container mx-auto block">
                    <div className="flex flex-col-reverse lg:flex-row items-center">
                        <div className="w-full lg:w-1/2 px-2 lg:px-4">
                            <h2
                                data-aos="fade-down"
                                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
                            >
                                Why Choose Advilite's Digital Marketing Service?
                            </h2>
                            <ul>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Tailored Strategies: We understand that no two businesses are alike. Our digital marketing experts craft customized strategies that align with your unique goals and audience.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Multi-Channel Approach: We leverage a diverse array of digital channels, including SEO, social media, PPC advertising, email marketing, and content marketing, to ensure your brand is visible and engaging on multiple fronts.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Data-Driven Insights: Our team employs data analytics to continuously monitor and optimize your campaigns. This data-driven approach maximizes your ROI and keeps your marketing efforts ahead of the curve.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Targeted Campaigns: We help you pinpoint your ideal audience and tailor campaigns to speak directly to them, fostering strong connections and conversions.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Brand Building: Our strategies not only drive immediate results but also focus on building a lasting online presence and brand authority.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Conversion Optimization: We fine-tune your digital assets to ensure that visitors take the desired actions, whether it's making a purchase, signing up, or requesting more information.
                                </li>
                            </ul>
                        </div>
                        <div className="w-full lg:w-1/2 px-2 md:px-2">
                            <img
                                src={whychoose}
                                className="mx-auto block"
                                alt="advilite why choose"
                                data-aos="zoom-in-up"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-16 px-2">
                <div className="">
                    <div className="container mx-auto block">
                        <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
                            Why Partner with Advilite?
                        </h2>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            Advilite is more than just a digital marketing service; we're your dedicated partners in navigating the digital landscape. With a passion for digital innovation and a commitment to your success, we empower you to reach new heights online.
                        </p>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            Ready to propel your online presence and drive growth? Contact Advilite today to explore how our Digital Marketing Service can elevate your brand, engage your audience, and achieve your business objectives.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default DigitalMarketingService;