import React, { useEffect } from "react";
import Footer from "./Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import mainimg from "../img/audit assurance graphics/taxhub-auditassurance-main.webp";
import secondimg from "../img/audit assurance graphics/taxhub-auditassurance-second-section.webp";
import externalaudit from "../img/audit assurance graphics/taxhub-auditassurance-external-audit.webp";
import compliancea from "../img/audit assurance graphics/taxhub-compliance-audit.webp";
import infoaudit from "../img/audit assurance graphics/taxhub-infomation-system-audit-service.webp";
import vataudits from "../img/audit assurance graphics/taxhub-vat-audit_service.webp";
import duediligence from "../img/audit assurance graphics/taxhub-due-diligence-report.webp";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const AuditAssurance = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });

    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  });

  return (
    <>
      <Helmet>
        <title>
          Advilite | Audit & Assurance | Best Auditing Services In UAE
        </title>
        {/* <meta
          name="title"
          content="Taxhub | Audit & Assurance | Best Auditing Services In UAE"
        /> */}

        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />

        <meta
          name="og_title"
          property="og:title"
          content="Advilite | Audit & Assurance | Best Auditing Services In UAE"
        />
        <meta
          name="Description"
          content="Advilite has top auditing services in UAE. They assist businesses in maintaining compliance, risk management, & financial performance optimization. "
        />
        <meta
          property="og:description"
          content="Advilite has top auditing services in UAE. They assist businesses in maintaining compliance, risk management, & financial performance optimization."
        />
        <meta
          name="og_image"
          property="og:image"
          content="https://advilite.com/static/media/taxhub-auditassurance-main.4e2f2b81ba6a523deebf.webp"
        />
        <meta
          name="og_url"
          property="og:url"
          content="https://advilite.com/audit-and-assurance-services"
        />
        <meta
          name="og_site_name"
          property="og:site_name"
          content="advilite.com"
        ></meta>
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Advilite | Audit & Assurance | Best Auditing Services In UAE"
        />
        <meta
          name="twitter:description"
          content="Advilite has top auditing services in UAE. They assist businesses in maintaining compliance, risk management, & financial performance optimization."
        />
        <meta
          name="twitter:image"
          content="https://advilite.com/taxhub-auditassurance-main.4e2f2b81ba6a523deebf.webp"
        />
        <meta
          name="twitter:url"
          content="https://advilite.com/audit-and-assurance-services"
        />

        <link
          rel="canonical"
          href="https://advilite.com/audit-and-assurance-services"
        />
        {/* <meta
          property="og:title"
          content="Taxhub | Audit & Assurance | Best Auditing Services In UAE"
        />
        <meta property="og:site_name" content="taxhub.ae" />
        <meta
          property="og:url"
          content="https://taxhub.ae/audit-and-assurance-services"
        />
        <meta
          property="og:description"
          content="Taxhub has top auditing services in UAE. They assist businesses in maintaining compliance, risk management, & financial performance optimization. "
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://taxhub.ae/static/media/Audit-&-Assurance.jpg"
        /> */}
      </Helmet>
      <section className="bg-black">
        <div className="px-2 bg-white h-full py-16">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col-reverse md:flex-row justify-center items-center h-full">
              <div className="w-full md:w-1/2">
                <h2
                  className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic"
                  data-aos="fade-right"
                >
                  Best Auditing Services in Dubai: Ensuring Financial
                  Credibility and Transparency
                </h2>
                <p
                  className="text-greens bitter mt-2 mb-6 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                  data-aos="fade-up"
                >
                  Auditing services have become a crucial aspect of businesses
                  worldwide, providing accuracy and transparency of financial
                  information to stakeholders and investors. In today's global
                  market, the relevance of the Auditing process is more critical
                  than ever, making quality Auditing services paramount for any
                  company to determine its financial position accurately.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base sm:text-lg md:text-base lg:text-lg py-4 px-4 sm:px-10 text-white hover:text-greenn domine hover:bg-white hover:text-second m-0 border-2 border-greenn hover:border-greenn font_primary bg-greenn"
                  data-aos="fade-up"
                >
                  Schedule an appointment
                </Link>
              </div>
              <div className="w-full md:w-1/2">
                <img
                  src={mainimg}
                  alt="advilite-business-consultancy"
                  data-aos="zoom-in-up"
                  className="mx-auto block w-3/4 rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 gradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify">
                At Advilite, we understand the importance of maintaining
                authenticity, accounting services, and integrity in Auditing.
                Our team of experts provides the best internal and external
                auditing services in Dubai, UAE, at a reasonable price, keeping
                the needs of our clients as our top priority.
              </p>
              <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify">
                Following international Auditing standards, our Auditors have
                the skills to fulfill client business needs in tune with the
                modern market dynamics consistent with UAE's financial
                ecosystem. Our Audit reports are approved by the Ministry of
                Economy (MOE), guaranteeing their reliability and accuracy.
              </p>
              <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify">
                The significance of financial credibility and transparency has
                been growing in the UAE's auditing trend as investors,
                stakeholders, and partners increasingly recognize the need for
                these elements to ensure their organization's financial
                security. With our top-notch auditing services, businesses can
                rest assured that their financial information is accurate and
                transparent, meeting the expectations of stakeholders and
                investors.
              </p>
            </div>
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={secondimg}
                className="mx-auto block"
                alt="advilite why choose"
                data-aos="flip-down"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="">
          <div className="container mx-auto block">
            <h2
              data-aos="fade-up"
              className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-greens font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block"
            >
              If you are looking for professional Auditing services in Dubai,
              UAE, look no further than Advilite. Our team of experts provides
              reliable and accurate Audits that can help your organization make
              informed decisions and achieve its goals.
            </h2>
            <Link
              to="/contact-us"
              data-aos="fade-up"
              className="w-max block mx-auto text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      <section className="bg-blackn py-16 px-2 md:px-4">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-[#9bff9b] font-bold pt-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
            We give Internal Audits services
          </h2>
          <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block">
            Our internal Audits help organizations identify potential risk areas
            and assess internal controls' effectiveness. We provide valuable
            insights to help your organization enhance its operations and
            minimize risk.
          </p>
          <div className="flex flex-col lg:flex-row justify-around items-center mt-6">
            <div className="w-full lg:w-[40%]">
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-[#9bff9b] font-bold pt-4 centurygothic">
                Importance of Internal Audits
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-white">
                  It helps assess business risks
                </li>
                <li className="domine text-white">
                  Assures effective control of business activities and functions
                </li>
                <li className="domine text-white">
                  Improves the efficiency of l business operations
                </li>
                <li className="domine text-white">
                  It makes room for regulatory and legal compliance
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-[40%]">
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-[#9bff9b] font-bold pt-4 centurygothic">
                Benefits of Internal Audits
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-white">
                  It helps to identify specific business challenges
                </li>
                <li className="domine text-white">
                  Improves quality of business
                </li>
                <li className="domine text-white">
                  Internal audits allow nipping crises before they even begin to
                  threaten your quality
                </li>
                <li className="domine text-white">
                  It makes room for regulatory and legal compliance
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2 md:px-4">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-greenn font-bold pt-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
            We give External Audit services
          </h2>
          <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block">
            Our external Audits offer an impartial evaluation of a company's
            financial statements to ensure their accuracy and compliance with
            applicable regulations and accounting standards.
          </p>
        </div>
        <div className="container mx-auto block mt-8">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={externalaudit}
                className="mx-auto block"
                alt="advilite why choose"
                data-aos="zoom-in-up"
              />
            </div>
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl text-greenn font-bold pt-4 centurygothic">
                Importance of External Audit
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-black">
                  External Audits help to ensure tax compliance
                </li>
                <li className="domine text-black">
                  External Audits provide independent credibility
                </li>
                <li className="domine text-black">
                  Allows a critique of internal processes
                </li>
                <li className="domine text-black">
                  External Audits provide a quality control mechanism for
                  internal Audits
                </li>
              </ul>
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl text-greenn font-bold pt-4 centurygothic">
                Benefits of External Audit
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-black">
                  It acts as an error-checking mechanism
                </li>
                <li className="domine text-black">
                  Drive insightful expert suggestions
                </li>
                <li className="domine text-black">
                  Ensure validity for company books of accounts and other
                  finance reports
                </li>
                <li className="domine text-black">
                  It assures that a company’s financial data is legal
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-auditasucomplianceser bg-cover bg-no-repeat bg-center">
        <div className="py-24 px-2">
          <div className="container mx-auto block">
            <h2
              data-aos="fade-up"
              className="text-2xl my-1 sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-[#9bff9b] font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center"
            >
              We give the Compliance Audit Services.
            </h2>
            <p
              data-aos="fade-up"
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-center"
            >
              Compliance Audits can broadly categorize into:
            </p>
            <h2
              data-aos="fade-up"
              className="text-2xl mt-4 sm:text-2xl lg:text-3xl xl:text-4xl text-[#9bff9b] font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center"
            >
              Statutory Audit
            </h2>
            <p
              data-aos="fade-up"
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
            >
              The conduct of a statutory audit bestows upon one the capacity to
              ascertain and comprehend the accuracy and integrity of an
              organization's financial records and statements. It is not a
              universal obligation for all firms to undergo such audits. Only
              specific private business sectors (insurance and aviation), as
              well as governmental departments and organizations, are subject to
              it, intermittently necessitating their compliance with the
              statutory audit mandate. Despite the governmental imposition of
              statutory audit, the company has several advantages in executing
              it.
            </p>
            <h2
              data-aos="fade-up"
              className="text-2xl mt-4 sm:text-2xl lg:text-3xl xl:text-4xl text-[#9bff9b] font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center"
            >
              Private/ Special Audit
            </h2>
            <p
              data-aos="fade-up"
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
            >
              Ensuring adherence to the diverse set of statutory regulations
              established by different government departments becomes
              increasingly crucial upon the incorporation of your business.
              Compliance audit in Dubai represents a fundamental fact in the
              sustenance of your enterprise. In the contemporary world of
              cut-throat competition, it becomes indispensable to firmly ground
              your business by meeting these requirements, as non-compliance
              with statutory regulations may entail many legal repercussions.
              The principal objective of statutory compliance aims to secure a
              favorable, supportive, and impartial market environment that
              enables businesses to conduct their operations. However, the
              advantages of statutory compliance extend beyond this primary
              purpose.
            </p>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="container mx-auto block mt-8">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-greenn font-bold pt-4 centurygothic">
                Importance of Compliance Audits
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-black">
                  It helps to save you from penalties
                </li>
                <li className="domine text-black">
                  It helps a business drive immense repute
                </li>
                <li className="domine text-black">
                  It provides insights into your business strength
                </li>
                <li className="domine text-black">
                  It can help build risk management strategies
                </li>
              </ul>
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-greenn font-bold pt-4 centurygothic">
                Benefits of Compliance Audits
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-black">
                  It helps improve the goodwill of the Company
                </li>
                <li className="domine text-black">
                  Statutory Audit helps increase public trust and the
                  reliability of the business.
                </li>
                <li className="domine text-black">
                  It enhances the creditworthiness of the business
                </li>
                <li className="domine text-black">
                  A great statutory audit report can be to attract investment
                  from a company
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={compliancea}
                data-aos="flip-left"
                className="mx-auto block"
                alt="advilite why choose"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 gradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <h2
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-[#9bff9b] font-bold py-4 centurygothic"
                data-aos="fade-right"
              >
                We give the Information System Audit Services
              </h2>
              <p
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                The advent of information technology has revolutionized how
                companies undertake their business activities and render
                services. Thus, businesses across the globe employ intricate and
                extensive information systems to enhance their operations'
                efficiency and efficacy.
              </p>
              <p
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                To guarantee optimal performance and identify potential
                vulnerabilities, it is indispensable for companies to conduct
                audits of their information systems in Dubai. Since most
                enterprises handle sensitive data from diverse firms, an
                information system audit assumes paramount significance.
                Equipped with an adept team and offering comprehensive services,
                AM Audit stands ready to provide all the necessary support
                required for this audit in Dubai.
              </p>
              <p
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                Conducting an audit of an organization's technology systems and
                infrastructure that support its operations represents a crucial
                undertaking.
              </p>
            </div>
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={infoaudit}
                className="mx-auto block"
                data-aos="zoom-in-up"
                alt="advilite why choose"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="container mx-auto block">
          <div className="flex flex-col lg:flex-row justify-around mt-6">
            <div className="w-full lg:w-[40%]">
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-greenn font-bold pt-4 centurygothic">
                Importance of Information System Audits
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-black">
                  It assures that the IT systems are sufficiently secured,
                  delivers trustworthy information to users, and are effectively
                  managed to deliver the desired results.
                </li>
                <li className="domine text-black">
                  It also reduces the risk of data tampering, data loss or
                  leakage, service disruption, and poor management of IT
                  systems.
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-[40%]">
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-greenn font-bold pt-4 centurygothic">
                Benefits of Information System Audits
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-black">
                  It helps discover any breach into the technology
                  infrastructure of systems
                </li>
                <li className="domine text-black">
                  It can help the business create a safer and more secure
                  technology ecosystem
                </li>
                <li className="domine text-black">
                  It will help the business protect the clients, data, and other
                  sensitive information
                </li>
                <li className="domine text-black">
                  It will keep security threats and breaches at bay by
                  introducing better safety{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-audasuvatser bg-cover bg-no-repeat bg-center ">
        <div className="py-24">
          <div className="container mx-auto block">
            <h2
              className="text-2xl my-1 sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-[#9bff9b] font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center"
              data-aos="fade-up"
            >
              We give VAT Audit Services
            </h2>
            <p
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
              data-aos="fade-up"
            >
              Performing a VAT audit in the UAE represents a necessary measure
              to ensure that a company adheres to the VAT laws established by
              the country. The VAT audit provides valuable insights into the
              company's ability to comply with all the VAT provisions and
              conditions.
            </p>
            <p
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
              data-aos="fade-up"
            >
              By examining the company's transactions against the established
              framework, the VAT audit facilitates an assessment of the accuracy
              of the information submitted to the Federal Tax Authority.
            </p>
            <p
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
              data-aos="fade-up"
            >
              Compliance with the VAT regulations in the UAE confers several
              advantages to a company. Thus, it is advisable to conduct a VAT
              audit with the assistance of a professional firm to optimize its
              efficiency. Apart from averting potential legal complications and
              consequences, a company has numerous benefits from fully complying
              with the UAE's VAT rules and regulations.
            </p>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="container mx-auto block mt-8">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={vataudits}
                className="mx-auto block"
                data-aos="flip-down"
                alt="advilite why choose"
              />
            </div>
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl text-greenn font-bold pt-4 centurygothic">
                Importance of VAT Audits
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-black">
                  It prevents any risk linked to a tax audit.
                </li>
                <li className="domine text-black">
                  A VAT audit aims to ensure compliance with VAT regulations
                  about goods and invoice flows.
                </li>
              </ul>
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl text-greenn font-bold pt-4 centurygothic">
                Benefits of VAT Audits
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-black">
                  It helps authenticate the financial transaction of the company
                </li>
                <li className="domine text-black">
                  It will assure the government that the company is clean and
                  legit
                </li>
                <li className="domine text-black">
                  It helps augment the goodwill of the company among the public
                  and the competition
                </li>
                <li className="domine text-black">
                  It helps identify any fallouts in VAT compliance
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-blackn py-16 px-2 md:px-4">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-[#9bff9b] font-bold pt-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
            We give Statutory Audit Services
          </h2>
          <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify">
            The accuracy of an organization's financial records and statements
            can be ascertained through statutory audits, determining if a
            business is furnishing accurate information to the public. Due to
            their intricate and comprehensive nature, only a professional and
            seasoned auditing firm can conduct statutory audits. Endowed with
            years of experience and expert insights, AM Audit ranks among this
            domain's most highly regarded names.
          </p>
          <div className="flex flex-col lg:flex-row justify-around items-center mt-6">
            <div className="w-full lg:w-[40%]">
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-[#9bff9b] font-bold pt-4 centurygothic">
                Importance of Statutory Audit
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-white">
                  A statutory audit is required by law to verify the accuracy of
                  a company's financial statements or records
                </li>
                <li className="domine text-white">
                  The primary objective of a statutory audit is to assess an
                  organization's financial position by scrutinizing its
                  accounting transactions and determining whether they present a
                  truthful and impartial portrayal
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-[40%]">
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-[#9bff9b] font-bold pt-4 centurygothic">
                Benefits of Statutory Audit
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-white">
                  It helps improve the goodwill of the company
                </li>
                <li className="domine text-white">
                  A statutory audit helps increase public trust and the
                  reliability of the business
                </li>
                <li className="domine text-white">
                  It enhances the creditworthiness of the business
                </li>
                <li className="domine text-white">
                  A great statutory audit report can attract investment from a
                  company.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2 md:px-4">
        <div className="container mx-auto block">
          <h2
            className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-greenn font-bold pt-4 centurygothic w-full md:w-[70%] mx-auto block text-center"
            data-aos="fade-up"
          >
            We give the Due Diligence Report
          </h2>
          <p
            className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
            data-aos="fade-up"
          >
            Due diligence auditing aims to evaluate a company's financial
            performance through a series of assessments. Such audits provide a
            comprehensive picture of the organization's financial status,
            including its earning potential, management capabilities, customer
            and supplier relationships, and other key aspects.
          </p>
          <p
            className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
            data-aos="fade-up"
          >
            Due diligence audits are commonly requested by individuals or
            companies seeking to engage in business with another organization.
            The primary objective of these audits is to thoroughly understand
            the target company's history, reputation, capacity, and performance
            before entering into any business transactions with them.
          </p>
        </div>
        <div className="container mx-auto block mt-8">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={duediligence}
                className="mx-auto block"
                data-aos="zoom-in-up"
                alt="advilite why choose"
              />
            </div>
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-greenn font-bold pt-4 centurygothic">
                Importance of Due Diligence Report
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-black">
                  For a business that wants to enter into a long-term business
                  relationship with another company, knowing if they are making
                  the correct business decision is essential.
                </li>
                <li className="domine text-black">
                  To gain quantifiable insights and reviews of the business, a
                  business must conduct a due diligence audit on another
                  company.
                </li>
              </ul>
              <p className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-greenn font-bold pt-4 centurygothic">
                Benefits of Due Diligence Report
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-black">
                  It is an effective risk assessment technique.
                </li>
                <li className="domine text-black">
                  It will help get the most comprehensive view of another
                  company.
                </li>
                <li className="domine text-black">
                  It can improve the reputation of a business in the market.
                </li>
                <li className="domine text-black">
                  It will help the business decide on beginning a business
                  relationship.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-16 px-2">
        <div className="">
          <div className="container mx-auto block">
            <h2
              className="text-2xl sm:text-2xl lg:text-3xl xl:text-3xl fredoka text-greens font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center"
              data-aos="fade-right"
            >
              Explore today how we can propel the growth and success of your
              business with our assistance.
            </h2>
            <Link
              to="/contact-us"
              className="w-max block mx-auto text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl"
              data-aos="fade-up"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AuditAssurance;
