import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import logo from "../img/taxhub-logo-name.png";
import industry1 from '../img/navindustries/taxhub-industry-1.webp';
import industry2 from '../img/navindustries/taxhub-industry-2.webp';
import industry3 from '../img/navindustries/taxhub-industry-3.webp';
import industry4 from '../img/navindustries/taxhub-industry-4.webp';
import industry5 from '../img/navindustries/taxhub-industry-5.webp';
import industry6 from '../img/navindustries/taxhub-industry-6.webp';
import industry7 from '../img/navindustries/taxhub-industry-7.webp';
import industry8 from '../img/navindustries/taxhub-industry-8.webp';
import industry9 from '../img/navindustries/taxhub-industry-9.webp';
import industry10 from '../img/navindustries/taxhub-industry-10.webp';
import industry11 from '../img/navindustries/taxhub-industry-11.webp';
import industry12 from '../img/navindustries/taxhub-industry-12.webp';
import industry13 from '../img/navindustries/taxhub-industry-13.webp';
import industry14 from '../img/navindustries/taxhub-industry-14.webp';

const Layout = () => {

  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <>
      <div>
        <div className="w-full py-2 bg-hovergreen">
          <div className="container mx-auto block">
            <div className="flex justify-center items-center px-2">
              <h2 className="text-center textgreenn domine text-sm sm:text-base md:text-lg lg:text-xl capitalize">
                Get your 1st consultation for FREE
              </h2>
              <Link
                to="/contact-us"
                className="animate-topbtn text-center ml-2 sm:ml-6 text-white bg-greenn focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-[12px] sm:text-sm md:text-base px-2 py-1 sm:px-4 sm:py-2 md:px-5 md:py-2.5 mr-1 md:mr-2 focus:outline-none dark:focus:ring-blue-800"
              >
                Click Here
              </Link>
            </div>
          </div>
        </div>
        <nav className="bg-blackn border-gray-200 dark:bg-gray-900 static lg:sticky top-0 w-full z-50 shadow-sm shadow-hovergreen">
          <div className="container px-4 mx-auto flex flex-wrap items-center justify-between py-4 lg:py-0">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
              <Link
                to="/"
                className="text-sm font-bold adjlogo leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
              >
                <img
                  src={logo}
                  className="mx-auto block "
                  alt="logo advilite"
                />
              </Link>
              <button
                className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <FontAwesomeIcon icon={faBars} className="text-white" />
              </button>
            </div>
            <div
              className={
                "lg:flex jusify-start lg:flex-grow items-center flex-col lg:flex-row" +
                (navbarOpen ? " flex" : " hidden")
              }
              id="example-navbar-danger"
            >
              <ul className="flex flex-col lg:flex-row list-none lg:mx-auto">
                <li className="nav-item" onClick={() => setNavbarOpen(false)}>
                  <Link
                    to="/"
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  >
                    <span className="ml-2">Home</span>
                  </Link>
                </li>
                <li className="nav-item" onClick={() => setNavbarOpen(false)}>
                  <Link
                    to="/about-us"
                    className="px-3 py-2 w-full lg:w-auto flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  >
                    <span className="ml-2">About Us</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <div className="dropdown float-left lg:overflow-hidden text-base border-none outline-none text-white">
                    <p className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75 ml-2 hover:cursor-pointer">
                      Services{" "}
                      <b>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="ml-1"
                        />
                      </b>
                    </p>
                    <div className="dropdown-content hidden static lg:absolute bg-transparent w-full left-0 z-0 lg:z-10 text-white bg-red">
                      <div className="header  text-white bg-red"></div>
                      <div className="row container mx-auto after:table after:clear-both bg-blackn shadow-md shadow-hovergreen">
                        <div className="column float-left w-[25.00%] p-2 bg-blackn h-auto">
                          {/* <h3>Category 1</h3> */}
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/audit-and-assurance-services"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Audit & Assurance
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/accounting-and-finance-services"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Accounting & Finance
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/business-set-up-services"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Business Setup
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/corporate-tax-services"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Corporate Tax
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/consultant-services"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Consultant Services
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/vat-accounting-services"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            VAT Accounting
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/compliances-taxes"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Tax Compliances Advice
                          </Link>
                        </div>
                        <div className="column float-left w-[25.00%] p-2 bg-blackn h-auto">
                          {/* <h3>Category 1</h3> */}
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/investor-visa"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Investors Visa
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/golden-visa"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Golden Visa
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/pro-services"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            PRO Services
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/professional-firm"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Professional Firm
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/outsourcing-cfo"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Outsourcing CFO Function
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/gulf-corporate-tax"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Gulf Corporate Tax
                          </Link>

                        </div>
                        <div className="column float-left w-[25.00%] p-2 bg-blackn h-auto">
                          {/* <h3>Category 1</h3> */}
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/advisory-bookkeeping"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Advisory & Bookkeeping
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/corporate-finance"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Corporate Finance
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/transaction-services"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Transaction Service
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/government-incentives"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Government Incentives
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/business-consultancy"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Business Consultancy
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/finance-advisory"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Finance & Advisory
                          </Link>
                        </div>
                        <div className="column float-left w-[25.00%] p-2 bg-blackn h-auto">
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/website-development"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Website development service
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/uiuxdesign-service"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            UI/UX Design Service
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/digital-marketing-service"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Digital Marketing Service
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/ecommerce-service"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            E-Commerce Service
                          </Link>
                          <Link
                            onClick={() => setNavbarOpen(false)}
                            to="/graphic-design-service"
                            className="float-none text-white p-2 no-underline block text-left hover:bg-lightgreen hover:text-greenn"
                          >
                            Graphic Design Service
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="dropdowni float-left overflow-hidden text-base border-none outline-none text-white">
                    <p className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:cursor-pointer text-white hover:opacity-75">
                      Industries <FontAwesomeIcon icon={faChevronDown} />
                    </p>
                    <div className="dropdown-content-i hidden absolute bg-transparent w-full left-0 z-10 text-white bg-red">
                      <div className="header  text-white bg-red"></div>
                      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-4 after:table after:clear-both w-full justify-center shadow-md shadow-hovergreen bg-blackn container mx-auto">
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/renewable-energy-and-sustainability-initiatives-in-uae"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry1}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/vat-in-uae-water-and-electricity-bills-to-be-subject-to-5-percent"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry2}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/all-about-smart-mobility-solutions-in-the-uae"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry3}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/film-production-company-in-uae-setup-guide"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry4}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a href="/" className="hover:cursor-pointer">
                            <img
                              src={industry5}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/vat-services-for-manufacturing-companies-in-uae"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry6}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/media-and-entertainment-company-in-uae"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry7}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>

                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/tourism-taxes-in-the-uae"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry8}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/latest-developments-in-grassroots-sports-in-the-uae"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry9}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/understanding-vat-on-financial-services-in-the-uae-guide-and-applicability"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry10}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/understanding-vat-for-schools-in-the-uae-taxhub"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry11}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/understanding-vat-exemption-for-healthcare-services-in-dubai-and-uae-taxhub"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry12}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/the-petroleum-industry-in-the-united-arab-emirates-uae"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry13}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                        <div className="columni float-left p-2 bg-blackn h-auto">
                          <a
                            href="https://advilite.com/blog/establishing-a-technical-services-business-in-dubai"
                            className="hover:cursor-pointer"
                          >
                            <img
                              src={industry14}
                              alt="industries advilite"
                              className="mx-auto block hover:scale-105"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item" onClick={() => setNavbarOpen(false)}>
                  <Link
                    to="/our-team"
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  >
                    <span className="ml-2">Our Teams</span>
                  </Link>
                </li>
                <li className="nav-item" onClick={() => setNavbarOpen(false)}>
                  <Link
                    to="/contact-us"
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  >
                    <span className="ml-2">Contact Us</span>
                  </Link>
                </li>
                <li className="nav-item" onClick={() => setNavbarOpen(false)}>
                  <Link
                    to="/blogs"
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  >
                    <span className="ml-2">Blogs</span>
                  </Link>
                </li>
              </ul>
              <a
                href="/contact-us"
                className="widget text-white bg-greenn focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 md:px-5 md:py-2.5 mr-1 md:mr-2 focus:outline-none dark:focus:ring-blue-800"
              >
                Make Appointment
              </a>
            </div>
          </div>
        </nav>
        <div className="content">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Layout;