import React, { useEffect } from 'react';
import imgbc from '../img/advisorybk/taxhub-advisory-bookkeeping.webp';
import whychoose from '../img/advisorybk/taxhub-why-use-taxhub-bookkeeping.webp';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { FaCheck } from 'react-icons/fa';

const ECommerceService = () => {

    useEffect(() => {

        Aos.init({ duration: 1500 });

        $(document).ready(function () {
            $(this).scrollTop(0);
        });

    });

    return (
        <>
            <section className="bg-black">
                <div className="px-2 bg-white h-full py-16">
                    <div className="container mx-auto block h-full">
                        <div className="flex flex-col-reverse md:flex-row justify-center items-center h-full">
                            <div className="w-full md:w-1/2">
                                <h2
                                    data-aos="fade-right"
                                    className="text-greenn text-3xl sm:text-4xl md:text-[2.6rem] lg:text-4xl xl:text-5xl font-semibold mb-6 centurygothic mt-8 md:mt-0"
                                >
                                    Your Gateway to Online Retail Success
                                </h2>
                                <p
                                    data-aos="fade-up"
                                    className="text-greens bitter mb-8 font-semibold mt-2 text-xl sm:text-lg domine text-justify"
                                >
                                    In the ever-evolving world of online retail, creating a seamless and profitable e-commerce experience is essential. Whether you're a seasoned e-commerce business or just starting, Advilite's E-commerce Service is here to empower you with cutting-edge solutions and strategies that drive sales and customer satisfaction.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2">
                                <img
                                    src={imgbc}
                                    alt="advilite-business-consultancy"
                                    className="mx-auto block w-3/4 rounded-xl"
                                    data-aos="zoom-in-up"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-16 gradienthalf">
                <div className="container mx-auto block">
                    <div className="flex flex-col-reverse lg:flex-row items-center">
                        <div className="w-full lg:w-1/2 px-2 lg:px-4">
                            <h2
                                data-aos="fade-down"
                                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
                            >
                                Why Choose Advilite's E-commerce Service?
                            </h2>
                            <ul>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Customized E-commerce Platforms: We tailor e-commerce solutions to your unique business needs, ensuring your online store is a perfect fit for your products and target audience.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    User-Friendly Design: Our expert designers craft intuitive, visually appealing, and responsive e-commerce websites that provide a delightful shopping experience.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Payment Integration: Secure and seamless payment processing is crucial for conversions. We integrate trusted payment gateways to boost customer trust and confidence.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Inventory Management: Stay in control with efficient inventory management systems that help you track stock levels, prevent overstocking or understocking, and optimize supply chain operations.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Multi-Channel Selling: Expand your reach by selling on multiple online platforms, including marketplaces like Amazon and eBay, to diversify your sales channels.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Conversion Optimization: Our strategies focus on increasing conversion rates, whether through optimizing product listings, improving checkout processes, or implementing effective sales funnels.
                                </li>
                            </ul>
                        </div>
                        <div className="w-full lg:w-1/2 px-2 md:px-2">
                            <img
                                src={whychoose}
                                className="mx-auto block"
                                alt="advilite why choose"
                                data-aos="zoom-in-up"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-16 px-2">
                <div className="">
                    <div className="container mx-auto block">
                        <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
                            Why Partner with Advilite?
                        </h2>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            Advilite is more than just an e-commerce service; we're your partners in building and growing your online retail empire. Our team's expertise, dedication, and commitment to your success will help you navigate the competitive e-commerce landscape and achieve your business goals.
                        </p>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            Ready to take your e-commerce business to the next level? Contact Advilite today to explore how our E-commerce Service can optimize your online store for success, increase sales, and delight your customers.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default ECommerceService;