import React, { useEffect } from 'react';
import imgbc from '../img/advisorybk/taxhub-advisory-bookkeeping.webp';
import whychoose from '../img/advisorybk/taxhub-why-use-taxhub-bookkeeping.webp';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { FaCheck } from 'react-icons/fa';

const WebsiteDevelopment = () => {

    useEffect(() => {

        Aos.init({ duration: 1500 });

        $(document).ready(function () {
            $(this).scrollTop(0);
        });

    });

    return (
        <>
            <section className="bg-black">
                <div className="px-2 bg-white h-full py-16">
                    <div className="container mx-auto block h-full">
                        <div className="flex flex-col-reverse md:flex-row justify-center items-center h-full">
                            <div className="w-full md:w-1/2">
                                <h2
                                    data-aos="fade-right"
                                    className="text-greenn text-3xl sm:text-4xl md:text-[2.6rem] lg:text-4xl xl:text-5xl font-semibold mb-6 centurygothic mt-8 md:mt-0"
                                >
                                    Crafted for Your Online Success
                                </h2>
                                <p
                                    data-aos="fade-up"
                                    className="text-greens bitter mb-8 font-semibold mt-2 text-xl sm:text-lg domine text-justify"
                                >
                                    In today's digital age, your website is often the first interaction potential customers have with your business. It's not just a website; it's your online storefront, your brand's digital ambassador, and a powerful tool for growth. At Advilite, we understand the importance of a well-designed and functional website, which is why we're thrilled to introduce our Website Development Service.
                                </p>
                                {/* <Link
                                    to="/contact-us"
                                    className="rounded-xl text-base sm:text-lg md:text-base lg:text-lg py-4 px-4 sm:px-10 text-white hover:text-greenn domine hover:bg-white hover:text-second m-0 border-2 border-greenn hover:border-greenn font_primary bg-greenn"
                                    data-aos="fade-up"
                                >
                                    File Your Taxes with Ease!
                                </Link> */}
                            </div>
                            <div className="w-full md:w-1/2">
                                <img
                                    src={imgbc}
                                    alt="advilite-business-consultancy"
                                    className="mx-auto block w-3/4 rounded-xl"
                                    data-aos="zoom-in-up"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-16 gradienthalf">
                <div className="container mx-auto block">
                    <div className="flex flex-col-reverse lg:flex-row items-center">
                        <div className="w-full lg:w-1/2 px-2 lg:px-4">
                            <h2
                                data-aos="fade-down"
                                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
                            >
                                Why Choose Advilite's Website Development Service?
                            </h2>
                            <ul>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Tailored Solutions: We don't believe in one-size-fits-all. Our website development service is customized to your unique business needs and objectives.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Cutting-Edge Design: Our team of skilled designers and developers create visually stunning and user-friendly websites that leave a lasting impression.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Responsive & Mobile-Ready: With mobile browsing on the rise, we ensure your website looks and performs flawlessly on all devices, enhancing user experience and search engine rankings.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    SEO Optimization: We implement SEO best practices from the ground up, helping your website rank higher on search engines and attracting organic traffic.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    E-commerce Expertise: If you're looking to sell products online, we specialize in developing secure and seamless e-commerce websites that drive sales.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Content Management: Easily manage and update your website with our user-friendly content management systems (CMS) like WordPress, making it effortless to keep your site fresh and engaging.
                                </li>
                            </ul>
                        </div>
                        <div className="w-full lg:w-1/2 px-2 md:px-2">
                            <img
                                src={whychoose}
                                className="mx-auto block"
                                alt="advilite why choose"
                                data-aos="zoom-in-up"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-16 px-2">
                <div className="">
                    <div className="container mx-auto block">
                        <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
                            Why Partner with Advilite?
                        </h2>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            We design websites that have the potential to advance your company. Our methodical approach to website development includes a foundation that allows us to finish the tasks we set out to do.
                        </p>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            Additionally, we concentrate on factors that can provide your online business a competitive edge in the relevant niche. Our team of developers has constructive conversations with clients to learn about their requirements prior to constructing websites.
                        </p>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            Additionally, our developers illustrate the potential and range of their value-added tasks. Additionally, these experts talk about technical elements of website construction in plain language that is accessible to everyone.
                        </p>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            As a result, we are the best website design business that offers transparency and uncompromised quality.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default WebsiteDevelopment;