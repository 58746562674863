import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutUs from './components/AboutUs';
import Careers from './components/Careers';
import CaseDetails from './components/CaseDetails';
import Cases from './components/Cases';
import ContactUs from './components/ContactUs';
import FAQ from './components/FAQ';
import Home from './components/Home';
import Layout from './components/Layout';
import OurTeam from './components/OurTeam';
import PageNotFound from './components/PageNotFound';
import Pricing from './components/Pricing';
import Services from './components/Services';
import SingleBlog from './components/SingleBlog';
import BusinessConsultancy from './components/BusinessConsultancy';
import ProfessionalFirm from './components/ProfessionalFirm';
import CorporteFinance from './components/CorporteFinance';
import AdvisoryBK from './components/AdvisoryBK';
import InderctNDirectTaxes from './components/InderctNDirectTaxes';
import OutsourcingCFO from './components/OutsourcingCFO';
import TransactionServices from './components/TransactionServices';
import GovernmentIncentives from './components/GovernmentIncentives';
import GulfCorporateTax from './components/GulfCorporateTax';
import InvestorsVisa from './components/InvestorsVisa';
import VatAccountingServices from './components/VatAccountingServices';
import GoldenVisa from './components/GoldenVisa';
import ProServices from './components/ProServices';
import FinanceAdvisory from './components/FinanceAdvisory';
import AuditAssurance from './components/AuditAssurance';
import AccountingNfinanceser from './components/AccountingNfinanceser';
import BusinessSetupServices from './components/BusinessSetupServices';
import CorporateTaxServices from './components/CorporateTaxServices';
import ConsultantServices from './components/ConsultantServices';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import DisplayBlogs from './components/DisplayBlogs';
import BlogLayoutOne from './components/BlogLayoutOne';
import BlogLayoutTwo from './components/BlogLayoutTwo';
import BlogLayoutThree from './components/BlogLayoutThree';
import DashboardBlogs from './components/DashboardBlogs';
import ContactDetailsDash from './components/ContactDetailsDash';
import LayoutOneDash from './components/LayoutOneDash';
import LayoutTwoDash from './components/LayoutTwoDash';
import LayoutThreeDash from './components/LayoutThreeDash';
import { useEffect } from 'react';
import ErrorBoundary from './components/ErrorBoundary';
import ConsultationforFREE from './components/ConsultationforFREE';
import WebsiteDevelopment from './components/WebsiteDevelopment';
import UIUXDesignService from './components/UIUXDesignService';
import DigitalMarketingService from './components/DigitalMarektingService';
import ECommerceService from './components/ECommerceService';
import GraphicDesigningService from './components/GraphicDesigningService';


const App = ({ canonicalUrl }) => {




  useEffect(() => {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute("href", canonicalUrl);
    }
  }, [canonicalUrl]);



  return (
    <>
      <ErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="careers" element={<Careers />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="services" element={<Services />} />
              <Route path="cases" element={<Cases />} />
              <Route path="case-details" element={<CaseDetails />} />
              <Route path="our-team" element={<OurTeam />} />
              <Route path="pricing" element={<Pricing />} />
              <Route path="faqs" element={<FAQ />} />
              <Route path="pagenotfound" element={<PageNotFound />} />
              <Route path="singleblog" element={<SingleBlog />} />
              <Route
                path="business-consultancy"
                element={<BusinessConsultancy />}
              />
              <Route
                path="consultation-for-free"
                element={<ConsultationforFREE />}
              />
              <Route path="professional-firm" element={<ProfessionalFirm />} />
              <Route path="corporate-finance" element={<CorporteFinance />} />
              <Route path="advisory-bookkeeping" element={<AdvisoryBK />} />
              <Route
                path="compliances-taxes"
                element={<InderctNDirectTaxes />}
              />
              <Route path="outsourcing-cfo" element={<OutsourcingCFO />} />
              <Route
                path="transaction-services"
                element={<TransactionServices />}
              />
              <Route
                path="government-incentives"
                element={<GovernmentIncentives />}
              />
              <Route path="gulf-corporate-tax" element={<GulfCorporateTax />} />
              <Route
                path="vat-accounting-services"
                element={<VatAccountingServices />}
              />
              <Route path="investor-visa" element={<InvestorsVisa />} />
              <Route path="golden-visa" element={<GoldenVisa />} />
              <Route path="pro-services" element={<ProServices />} />
              <Route path="finance-advisory" element={<FinanceAdvisory />} />
              <Route path="website-development" element={<WebsiteDevelopment />} />
              <Route path="uiuxdesign-service" element={<UIUXDesignService />} />
              <Route path="ecommerce-service" element={<ECommerceService />} />
              <Route path="digital-marketing-service" element={<DigitalMarketingService />} />
              <Route path="graphic-design-service" element={<GraphicDesigningService />} />
              <Route
                path="audit-and-assurance-services"
                element={<AuditAssurance />}
              />
              <Route
                path="accounting-and-finance-services"
                element={<AccountingNfinanceser />}
              />
              <Route
                path="business-set-up-services"
                element={<BusinessSetupServices />}
              />
              <Route
                path="corporate-tax-services"
                element={<CorporateTaxServices />}
              />
              <Route
                path="consultant-services"
                element={<ConsultantServices />}
              />
              <Route path="blogs" element={<DisplayBlogs />} />
              <Route path="blog/:blogid" element={<BlogLayoutOne />} />
              <Route path="blog/:blogid" element={<BlogLayoutTwo />} />
              <Route path="blog/:blogid" element={<BlogLayoutThree />} />
              <Route path="signin" element={<Login />} />
              <Route path="dashboard" element={<Dashboard />}>
                <Route path="blogs" element={<DashboardBlogs />} />
                <Route
                  path="contact-details"
                  element={<ContactDetailsDash />}
                />
                <Route path="layoutone" element={<LayoutOneDash />} />
                <Route path="layouttwo" element={<LayoutTwoDash />} />
                <Route path="layoutthree" element={<LayoutThreeDash />} />
              </Route>
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          {/* <Sitemap routes={routes} /> */}
        </BrowserRouter>
      </ErrorBoundary>
    </>
  );
};

export default App;