export const items = [
    {
        id: 0,
        title: 'Who may register for VAT?',
        content: 'UAE-resident businesses who make taxable supplies in the UAE may voluntarily register for VAT if the value of their taxable supplies and imports, or taxable expenses, incurred in any past period of 12 months or less exceeded, or is expected to exceed in the next 30 days, the voluntary registration threshold of AED187,500.',
    },
    {
        id: 1,
        title: 'When are businesses supposed to start registering for VAT?',
        content: 'A person required to register for VAT must submit a registration application to the FTA within 30 days of reaching the Mandatory threshold). Registration applications will be submitted via the EmaraTax portal.',
    },
    {
        id: 2,
        title: 'Who can benefit from using Advilite?',
        content: 'Anyone interested in learning about tax regulations in the UAE can benefit from using Advilite. This includes individuals, businesses, and tax professionals.',
    },
    {
        id: 3,
        title: 'What services does Advilite offer?',
        content: 'Advilite offers various services, including tax consultation, VAT registration, VAT return filing, tax planning, and tax compliance reviews. It also provides free resources such as articles and guides on tax regulations in the UAE.',
    },
    {
        id: 4,
        title: 'How long does it take to get a response from Advilite customer service?',
        content: 'Advilite customer service aims to respond to all inquiries within 24 hours. However, response times may vary depending on the nature of your inquiry.',
    },
    {
        id: 5,
        title: 'What happens if I don’t notify my requirement to be registered for VAT or am delayed in informing the FTA?',
        content: 'If you fail to notify the FTA of a requirement to register for VAT within the timeframe specified in the Tax Law, an administrative penalty of AED10,000 will be charged against you. The FTA may issue a tax assessment by collecting any potentially underpaid VAT.',
    },
    {
        id: 6,
        title: 'Does Advilite provide services for individuals and small businesses?',
        content: 'Yes, Advilite provides services for both individuals and small businesses. They can assist with tax planning, compliance, and other tax-related matters.',
    },
    {
        id: 7,
        title: 'What is a professional firm in the UAE?',
        content: 'A professional firm in the UAE is a business established following the UAE Civil Code, where the main objective is to engage in the practice of a profession.',
    },
    {
        id: 8,
        title: 'What are the benefits of setting up a professional firm in the UAE for taxation purposes?',
        content: 'The benefits include tax savings, better management of tax obligations, and access to tax professionals.',
    },
    {
        id: 9,
        title: 'Can a professional firm apply for VAT registration in the UAE?',
        content: 'Yes, a professional firm with an annual turnover of AED 375,000 or more must register for VAT in the UAE.',
    },
]