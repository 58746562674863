import one from '../img/investorsvisa/taxhub-get-your-way.webp';
import imagetwo from '../img/investorsvisa/taxhub-UAE-investor-Visa-overview.webp';
import React, { useEffect } from 'react'
import benefits from '../img/investorsvisa/taxhub-benefits-of-getting-visa.webp';
import documents from '../img/investorsvisa/taxhub-documents-req-visa.webp';
import howto from '../img/investorsvisa/taxhub-how-to-apply-Visa.webp';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { Helmet } from 'react-helmet';

const InvestorsVisa = () => {

  useEffect( () => {

    Aos.init({ duration: 1500 });

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

  } );

  return (
    <>
      <Helmet>
        <title>Advilite | Investors Visa | Investor visa services in UAE</title>
        <meta
          name="title"
          content="Advilite | Investors Visa | Investor visa services in UAE"
        />
        <meta
          name="description"
          content="Advilite's team of UAE visa processing experts is available to assist you at every step. Advilite handle the application process for your UAE investor visa. "
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://advilite.com/investor-visa" />
        <meta
          property="og:title"
          content="Advilite | Investors Visa | Investor visa services in UAE"
        />
        <meta property="og:site_name" content="advilite.com" />
        <meta property="og:url" content="https://advilite.com/investor-visa" />
        <meta
          property="og:description"
          content="Advilite's team of UAE visa processing experts is available to assist you at every step. Advilite handle the application process for your UAE investor visa. "
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://advilite.com/static/media/taxhub-get-your-way.1a79fe6a7edf9a32687d.webp"
        />
      </Helmet>
      <section className="bg-black">
        <div className="px-2 bg-white h-full py-16">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col md:flex-row justify-center items-center h-full">
              <div className="w-full md:w-1/2">
                <img
                  src={one}
                  alt="advilite-business-consultancy"
                  className="mx-auto block w-3/4 rounded-xl"
                  data-aos="zoom-in-up"
                />
              </div>
              <div className="w-full md:w-1/2">
                <h2
                  data-aos="fade-right"
                  className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl text-greenn font-bold py-4 centurygothic"
                >
                  Get Your UAE Investor Visa in a Simple & Hassle-Free Way
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-greens bitter mt-2 mb-6 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  The UAE is renowned for being a welcoming location for
                  investors worldwide to set up business activities. UAE has a
                  variety of real estate and company investment opportunities
                  that can produce profitable long-term results.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base sm:text-lg md:text-base lg:text-lg py-4 px-4 sm:px-10 text-white hover:text-greenn domine hover:bg-white hover:text-second m-0 border-2 border-greenn hover:border-greenn font_primary bg-greenn"
                  data-aos="fade-up"
                >
                  Professional Tax Team
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-blackn py-16 px-2 md:px-4">
        <div className="container mx-auto block">
          <div className="flex flex-col lg:flex-row justify-around items-center">
            <div className="w-full lg:w-[40%]">
              <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify">
                An investor visa is first needed if you're an expat and want to
                start investing in the UAE. Advilite can assist you with
                obtaining your UAE investor visa quickly so you can start
                carrying out your investment plans without delay.
              </p>
            </div>
            <div className="w-full lg:w-[40%]">
              <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify">
                We assist specialists in learning about the prerequisites for
                obtaining an investment visa for the UAE. We also oversee the
                complete visa process on your behalf to ensure you receive your
                UAE investor visa as quickly as possible.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="w-full md:w-1/2">
              <img
                src={imagetwo}
                alt="advilite-business-consultancy"
                className="mx-auto block rounded-xl"
                data-aos="zoom-in-up"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-2xl xl:text-3xl fredoka text-greenn font-bold py-4 centurygothic"
              >
                UAE Investor Visa Overview
              </h2>
              <p
                data-aos="fade-right"
                className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                The Dubai Investment Visa, also known as the Dubai Partner Visa,
                is an option for foreign nationals seeking to engage in business
                activities within the United Arab Emirates. This visa provides
                the added benefit of being able to sponsor family members.
              </p>
              <p
                data-aos="fade-right"
                className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                You can also apply for a UAE ID card if you have a UAE
                investment visa. You can open a bank account, obtain a driver's
                license, and use several beneficial services only available to
                investors. Under one of the following categories, you may apply
                for an investment visa in the UAE:
              </p>
              <ul data-aos="fade-right" className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-greens">
                  Investment in Residential Property
                </li>
                <li className="domine text-greens">Investment in a Company</li>
                <li className="domine text-greens">Long-Term Residence</li>
                <li className="domine text-greens">Retirement</li>
              </ul>
              <Link
                to="/contact-us"
                data-aos="fade-up"
                className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl"
              >
                Book A Free Consultation
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse md:flex-row justify-center items-center">
            <div className="w-full md:w-1/2">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-2xl xl:text-3xl fredoka text-greenn font-bold py-4 centurygothic"
              >
                Benefits of Getting a UAE Investor Visa
              </h2>
              <ul data-aos="fade-up" className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-greens">
                  With this visa, you are eligible for a three-year UAE
                  residency.
                </li>
                <li className="domine text-greens">
                  Your immediate family members may be sponsored to shift to the
                  UAE.
                </li>
                <li className="domine text-greens">
                  Those with an investment visa for the UAE can easily travel
                  between all GCC nations. Getting travel visas for GCC nations
                  doesn't require investors to face many difficulties. For
                  instance, you can only readily go to these areas if you hold
                  an employment visa.
                </li>
                <li className="domine text-greens">
                  With this visa, your residency in the UAE will be recovered if
                  you spend up to a year living elsewhere.
                </li>
                <li className="domine text-greens">
                  You may have a better chance of receiving the UAE golden visa
                  if you have an investment visa and operate a prosperous firm
                  there.
                </li>
              </ul>
              <Link
                to="/contact-us"
                data-aos="fade-up"
                className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl"
              >
                Book A Free Consultation
              </Link>
            </div>
            <div className="w-full md:w-1/2 px-4">
              <img
                src={benefits}
                alt="advilite-business-consultancy"
                data-aos="zoom-in-up"
                className="mx-auto block rounded-xl"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="w-full md:w-1/2">
              <img
                src={documents}
                alt="advilite-business-consultancy"
                className="mx-auto block rounded-xl"
                data-aos="zoom-in-up"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-2xl xl:text-3xl fredoka text-greenn font-bold py-4 centurygothic"
              >
                Documents Required for Getting UAE Investor Visa
              </h2>
              <p
                data-aos="fade-up"
                className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                The following are the documents required to obtain an investment
                visa for the UAE:
              </p>
              <ul data-aos="fade-up" className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-greens">
                  Bank Account IBAN of the sponsor
                </li>
                <li className="domine text-greens">
                  Memorandum of Association
                </li>
                <li className="domine text-greens">Establishment Card</li>
                <li className="domine text-greens">
                  Photograph with solid white background
                </li>
                <li className="domine text-greens">
                  Business partner’s UAE visa and passport copy (if needed)
                </li>
                <li className="domine text-greens">Sponsor’s emirate ID</li>
                <li className="domine text-greens">Trade license copy</li>
              </ul>
              <Link
                to="/contact-us"
                data-aos="fade-up"
                className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl"
              >
                Book A Free Consultation
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 gradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <h2
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
                data-aos="fade-right"
              >
                How to Apply for UAE Investor Visa
              </h2>
              <p
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                Here is how to obtain an investment visa for the UAE:
              </p>
              <ul className="list-disc ml-8 mt-6" data-aos="fade-up">
                <li className="domine text-white">
                  Register your company with DED.
                </li>
                <li className="domine text-white">
                  After that, you need to apply for the company establishment
                  card.
                </li>
                <li className="domine text-white">
                  You need to apply for an entry permit for the UAE.
                </li>
                <li className="domine text-white">
                  You need to get a medical test done and get the report.
                </li>
                <li className="domine text-white">
                  After that, you need to apply for the emirates ID.
                </li>
                <li className="domine text-white">
                  You’d need to apply for a health insurance place as your
                  preference.
                </li>
                <li className="domine text-white">
                  You need to get an investor visa stamp on your passport.
                </li>
              </ul>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 mb-8 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                Following these instructions should allow you to obtain your UAE
                investor visa after processing the application
              </p>
              <Link
                to="/contact-us"
                data-aos="fade-up"
                className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-greenn"
              >
                Book A Free Consultation
              </Link>
            </div>
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={howto}
                className="mx-auto block"
                alt="advilite why choose"
                data-aos="zoom-in-up"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="">
          <div className="container mx-auto block">
            <h2
              data-aos="fade-up"
              className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center"
            >
              Visit Advilite for UAE Investor Visa Services in Dubai, UAE
            </h2>
            <p
              data-aos="fade-up"
              className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify"
            >
              For prospective entrepreneurs and investors, obtaining an
              investment visa for the UAE can lead to many new opportunities.
              The UAE visa processing professionals at Advilite are here to help
              you at every stage. We can manage the application procedure for
              your UAE investor visa. To obtain your UAE investor visa quickly
              and straightforwardly, contact Advilite.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default InvestorsVisa;