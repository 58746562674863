import imgone from '../img/goldenvisa/taxhub-get-yout-uae-benefits.webp';
import imgtwo from '../img/goldenvisa/taxhub-UAE-golden-vis-overview.webp';
import imgthree from '../img/goldenvisa/taxhub-Benefits-of-UAE-golden-visa.webp';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet-async';
// import { Helmet } from 'react-helmet';

const GoldenVisa = () => {

    useEffect( () => {

      Aos.init({ duration: 1500 });

      $(document).ready(function(){
        $(this).scrollTop(0);
      });
    
    } );

  return (
    <>
      <Helmet>
        <title>Advilite | Golden Visa | Golden visa services in UAE</title>
        <meta
          name="title"
          content="Advilite | Golden Visa | Golden visa services in UAE"
        />
        <meta
          name="description"
          content="Get personalized guidance from Advilite to apply & secure UAE's Golden Visa for permanent residency across the Emirates. Perfect for business owners & service providers. "
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://advilite.com/golden-visa" />
        <meta
          property="og:title"
          content="Advilite | Golden Visa | Golden visa services in UAE"
        />
        <meta property="og:site_name" content="advilite.com" />
        <meta property="og:url" content="https://advilite.com/golden-visa" />
        <meta
          property="og:description"
          content="Get personalized guidance from Advilite to apply & secure UAE's Golden Visa for permanent residency across the Emirates. Perfect for business owners & service providers."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://advilite.com/static/media/taxhub-get-yout-uae-benefits.14dc4f6ba9d2e854b307.webp"
        />
      </Helmet>
      <section className="bg-black">
        <div className="px-2 bg-white h-full py-20">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col md:flex-row justify-center items-center h-full">
              <div className="w-full md:w-1/2">
                <img
                  src={imgone}
                  alt="advilite-business-consultancy"
                  className="mx-auto block w-3/4 rounded-xl"
                  data-aos="zoom-in-up"
                />
              </div>
              <div className="w-full md:w-1/2">
                <h2
                  data-aos="fade-right"
                  className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl text-greenn font-bold py-4 centurygothic"
                >
                  Get Your UAE Golden Visa to Enjoy Exclusive Benefits
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-greens bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  If you are searching for assistance in applying for a UAE
                  Golden Visa, you have arrived at the right destination.
                  Advilite provides personalized guidance to business owners and
                  service providers to successfully apply and secure the UAE
                  Golden Visa, enabling permanent residency throughout the
                  emirates.
                </p>
                <p
                  data-aos="fade-up"
                  className="text-greens bitter mt-2 mb-6 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  Everything from consulting to full-service visa processing is
                  included in our services. We aid prospective holders of golden
                  visas in comprehending the documentation criteria and
                  assembling the necessary materials to speed up the application
                  procedure. We have a staff of licensed visa processing experts
                  familiar with every step of the UAE Golden Visa procedure.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base sm:text-lg md:text-base lg:text-lg py-4 px-4 sm:px-10 text-white hover:text-greenn domine hover:bg-white hover:text-second m-0 border-2 border-greenn hover:border-greenn font_primary bg-greenn"
                  data-aos="fade-up"
                >
                  Get a free consultation
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 gradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
              >
                UAE Golden Visa Overview
              </h2>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 font-semibold text-base sm:text-lg md:text-lg lg:text-xl centurygothic text-justify"
              >
                Talented individuals and business professionals are increasingly
                choosing to live, work, and invest in the United Arab Emirates:
              </p>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                People worldwide are deciding to integrate themselves into the
                UAE's residential and economic environment to build a better
                future
              </p>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 mb-8 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                The UAE government provides numerous advantages for those
                considering moving permanently there. The UAE Golden Visa is one
                of these benefits.
              </p>
              <Link
                data-aos="fade-up"
                to="/contact-us"
                className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-greenn"
              >
                Book A Free Consultation
              </Link>
            </div>
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={imgtwo}
                className="mx-auto block"
                alt="advilite why choose"
                data-aos="flip-down"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse md:flex-row justify-around items-center">
            <div className="w-full md:w-1/2">
              <p className="text-greens bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify">
                The UAE Golden Visa is a long-term UAE visa for foreigners who
                intend to live permanently in the UAE. In the last few years,
                numerous changes have been made to the Golden Visa in the UAE.
                Based on conventional application requirements, it enables
                investors, business owners, and even students to apply for it.
              </p>
              <p className="text-greens bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify">
                The UAE Golden Visa was primarily created to support the growth
                of the corporate and talent markets in the emirates. Those who
                are officially considered inhabitants of the UAE and have
                contributed to its expansion and development are eligible for
                this incentive.
              </p>
            </div>
            <div
              data-aos="fade-right"
              className="w-full md:w-max py-4 px-2 sm:px-6 md:px-4 lg:px-10 rounded-xl bg-lightgreen"
            >
              <p className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify">
                Which individuals are eligible <br />
                to apply for a UAE Golden Visa?
              </p>
              <ul className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-greens">Entrepreneurs</li>
                <li className="domine text-greens">Investors</li>
                <li className="domine text-greens">Talented Professionals</li>
                <li className="domine text-greens">Scientists</li>
                <li className="domine text-greens">Humanitarian Pioneers</li>
                <li className="domine text-greens">Frontline Heroes</li>
                <li className="domine text-greens">Students & Graduates</li>
                <li className="domine text-greens">Creative Professionals</li>
                <li className="domine text-greens">Athletes</li>
                <li className="domine text-greens">Executive Directors</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-16 px-2">
        <div className="container mx-auto block">
          <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="w-full md:w-1/2">
              <img
                src={imgthree}
                alt="advilite-business-consultancy"
                className="mx-auto block rounded-xl"
                data-aos="zoom-in-up"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-2xl xl:text-3xl fredoka text-greenn font-bold py-4 centurygothic"
              >
                Benefits of UAE Golden Visa
              </h2>
              <ul data-aos="fade-up" className="list-disc ml-8 mt-6 mb-8">
                <li className="domine text-greens">
                  Upon obtaining the UAE Golden Visa, one is typically issued an
                  entry visa that spans six months. This interim period can be
                  utilized to complete the necessary application procedures for
                  the golden visa.
                </li>
                <li className="domine text-greens">
                  Having a sponsor when applying for a golden visa is not a
                  requisite.
                </li>
                <li className="domine text-greens">
                  The golden visa typically grants a long-term residency for 5
                  or 10 years and is usually renewable upon expiration.
                </li>
                <li className="domine text-greens">
                  Unlike a standard UAE visa, the golden visa permits one to
                  remain outside of the UAE for more than six months without the
                  risk of having their residency status canceled.
                </li>
                <li className="domine text-greens">
                  The golden visa enables one to sponsor family members for
                  residency in the UAE, regardless of age.
                </li>
                <li className="domine text-greens">
                  The golden visa allows for the sponsorship of unlimited
                  domestic helpers, per one's requirements.
                </li>
                <li className="domine text-greens">
                  In the event of the visa holder's demise, the golden visa
                  permits their family members to continue residing in the UAE
                  for the duration specified by the visa without interruption.
                </li>
                <li className="domine text-greens">
                  With the golden visa, one does not require a sponsored
                  employment visa.
                </li>
              </ul>
              <Link
                to="/contact-us"
                data-aos="fade-right"
                className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl"
              >
                Book A Free Consultation
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-16 px-2">
        <div className="">
          <div className="container mx-auto block">
            <h2
              data-aos="fade-up"
              className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center"
            >
              Contact Advilite for Professional UAE Golden Visa Assistance in
              Dubai, UAE
            </h2>
            <p
              data-aos="fade-up"
              className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify"
            >
              Obtaining a UAE Golden Visa has become easier than ever before! If
              you meet the necessary criteria, you can apply for a Golden Visa
              and secure permanent residency in the United Arab Emirates. If you
              need assistance comprehending and navigating the UAE Golden Visa
              eligibility criteria and application process, Advilite is here to
              help you. Our visa processing professionals help you obtain your
              UAE Golden visa to take advantage of the services and benefits the
              UAE provides to permanent citizens. To learn more about our Golden
              Visa services in the UAE, contact Advilite immediately.
            </p>
            <Link
              to="/contact-us"
              data-aos="fade-up"
              className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl w-max mx-auto block"
            >
              Book A Free Consultation
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default GoldenVisa;