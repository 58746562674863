import { faArrowRight, faBicycle, faBook, faChartBar, faChartLine, faChartPie, faDollarSign, faDroplet, faFileInvoiceDollar, faFilePen, faFileSignature, faFootball, faGasPump, faGears, faGraduationCap, faHandHoldingUsd, faHandshake, faIdCard, faLayerGroup, faLightbulb, faListCheck, faMicrochip, faPassport, faPeopleGroup, faPhotoFilm, faPlane, faPlay, faScrewdriverWrench, faSolarPanel, faStarOfLife, faUser, faUserTie, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import whychoose from '../img/taxhub-why-choose-taxhub-new.webp';
import FaqHome from './FaqHome';
import { items } from './jsfiledata/faqs-homepage';
import member1 from '../img/team/2.webp';
import member2 from '../img/team/3.webp';
import Footer from './Footer';
import Aos from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Helmet } from 'react-helmet';
// import whatsappIcon from './assets/whatsapp-icon.png'; // Replace with your icon path
// import callIcon from './assets/call-icon.png';
import { BsWhatsapp } from 'react-icons/bs';
import { IoMdCall } from 'react-icons/io';
import Popup from './Popup';
import ADS from '../img/ads-banner.jpg';

const Home = () => {

  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });


  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
  };

  const submitfunction = async (e) => {
    e.preventDefault();


    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "contact",
      data: inputs,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res?.Success) {
          setInputs(res?.Success);
          toast.success(res?.Success);
        } else {
          toast.error(res?.Error);
        }
      })
      .catch((err) => {
        throw err;
      });
  };


  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };


  useEffect(() => {
    Aos.init({ duration: 1500 });
  })

  useEffect(() => {

    // $(document).ready(function(){
    //   $(this).scrollTop(0);
    // });

  }, []);

  $(document).ready(function () {
    // $('#industry').addClass('bg-industryfinance');
    // industry1

    $("#industry").addClass("bg-industryfinance");
    $("#headingindustry").css("color", "#9bff9b");
    $(".transonhov").addClass(
      "bg-transparent border-2 border-[#ffffff3d] text-white"
    );

    let mobi1 = $('#mindustry1');
    let desk1 = $('#industry1');
    mobi1.add(desk1).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industryenergy');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi1.add(desk1).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industryenergy');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry2
    let mobi2 = $('#mindustry2');
    let desk2 = $('#industry2')
    mobi2.add(desk2).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industrywater');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi2.add(desk2).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industrywater');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry3
    let mobi3 = $('#mindustry3');
    let desk3 = $('#industry3')
    mobi3.add(desk3).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industrymobility');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi3.add(desk3).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industrymobility');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry4
    let mobi4 = $('#mindustry4');
    let desk4 = $('#industry4');
    mobi4.add(desk4).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industrymovies');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi4.add(desk4).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industrymovies');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry5
    let mobi5 = $('#mindustry5');
    let desk5 = $('#industry5')
    mobi5.add(desk5).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industryfood');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi5.add(desk5).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industryfood');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry6
    let mobi6 = $('#mindustry6');
    let desk6 = $('#industry6')
    mobi6.add(desk6).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industrymanufacture');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi6.add(desk6).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industrymanufacture');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry7
    let mobi7 = $('#mindustry7');
    let desk7 = $('#industry7')
    mobi7.add(desk7).mouseover(function () {

      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industrymedia');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi7.add(desk7).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industrymedia');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry8
    let mobi8 = $('#mindustry8');
    let desk8 = $('#industry8')
    mobi8.add(desk8).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industrytourism');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi8.add(desk8).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industrytourism');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry9
    let mobi9 = $('#mindustry9');
    let desk9 = $('#industry9')
    mobi9.add(desk9).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industrysport');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi9.add(desk9).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industrysport');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry10
    let mobi10 = $('#mindustry10');
    let desk10 = $('#industry10')
    mobi10.add(desk10).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industrypetroleum');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi10.add(desk10).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industrypetroleum');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry11
    let mobi11 = $('#mindustry11');
    let desk11 = $('#industry11')
    mobi11.add(desk11).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industryeducation');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi11.add(desk11).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industryeducation');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry 12
    let mobi12 = $('#mindustry12');
    let desk12 = $('#industry12')
    mobi12.add(desk12).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industryhealthcare');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi12.add(desk12).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industryhealthcare');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry 13
    let mobi13 = $('#mindustry13');
    let desk13 = $('#industry13')
    mobi13.add(desk13).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industryfinance');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi13.add(desk13).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industryfinance');
      $('.transonhov').removeClass('bg-transparent')
    })
    // industry 14
    let mobi14 = $('#mindustry14');
    let desk14 = $('#industry14')
    mobi14.add(desk14).mouseover(function () {
      $('#industry').removeClass('bg-gulfcorporate');
      $('#industry').addClass('bg-industrytechnical');
      $('#headingindustry').css('color', '#9bff9b')
      $('.transonhov').addClass('bg-transparent border-2 border-[#ffffff3d] text-white')
    })
    mobi14.add(desk14).mouseout(function () {
      $('#headingindustry').css('color', '#189047')
      $('#industry').removeClass('bg-industrytechnical');
      $('.transonhov').removeClass('bg-transparent')
    })
  });

  const [canonicalUrl, setCanonicalUrl] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCanonicalUrl(`${window.location.origin}${window.location.pathname}`);
    }
  }, []);

  const phoneNumber = '+971521122777'; // Replace with your phone number
  const whatsappNumber = '+971521122777'; // Replace with your WhatsApp number

  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleWhatsAppClick = () => {
    window.location.href = `https://wa.me/${whatsappNumber}`;
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    openPopup(); // Automatically open the popup on website load
  }, []);


  return (
    <>
      <Helmet>
        <title>Advilite </title>
        <meta
          name="title"
          content="Trusted & Simplify Tax Consultancy Services in UAE | Advilite"
        />
        <meta
          name="description"
          content="Advilite is a trusted provider of tax consultancy services in the UAE, dedicated to simplifying tax matters and assisting individuals and businesses efficiency."
        />
        <meta
          name="keyword"
          content="tax consultancy services UAE, Tax services in UAE, Tax consulting UAE, Tax advisors in Dubai, Tax planning solutions"
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <meta
          property="og:title"
          content="Trusted & Simplify Tax Consultancy Services in UAE | Advilite"
        />
        Advilite
        <meta property="og:site_name" content="Advilite" />
        <meta property="og:url" content="https://advilite.com/" />
        <meta
          property="og:description"
          content="Advilite is a trusted provider of tax consultancy services in the UAE, dedicated to simplifying tax matters and assisting individuals and businesses efficiency."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://advilite.com/static/media/taxhub-main-section-bg.c8927d4c6ff4be57047c.webp"
        />
      </Helmet>

      {/* <div className="flex flex-col justify-center items-center">
        {isPopupOpen && (
          <Popup imageUrl={ADS} onClose={closePopup} />
        )}
      </div> */}
      <section className="pt-[3.6rem] lg:pt-18 h-full md:h-screen bg-bgmob md:bg-mainbg bg-left md:bg-center bg-cover">
        <div className="h-full px-2">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col md:flex-row justify-center pb-16 lg:py-16 md:py-0 items-center h-full">
              <div className="w-full md:w-1/2 invisible"></div>
              <div className="w-full md:w-1/2">
                <p
                  className="text-white capitalize text-xl mb-4 domine"
                  data-aos="fade-right"
                >
                  Empowering Business Ventures
                </p>
                <h2
                  className="text-white text-3xl sm:text-5xl md:text-[2.6rem] lg:text-5xl  font-semibold border-l-4 border-greenn md:pl-4 lg:pl-6 mb-6 centurygothic"
                  data-aos="fade-up"
                >
                  Trusted Partner for <br></br>Comprehensive Business Solutions
                </h2>
                <p
                  className="text-base sm:text-lg md:text-base lg:text-xl  text-white mb-8 domine"
                  data-aos="fade-up"
                >
                  Simplify your business and maximize your returns
                </p>
                <div className="flex items-center">
                  <Link
                    to="/contact-us"
                    className="rounded-xl text-base sm:text-lg md:text-base lg:text-lg py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-greenn"
                    data-aos="fade-up"
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fixed z-50 bottom-4 right-4 flex flex-col gap-2">
          <div onClick={handleCallClick} className="bg-blue-500 text-white w-14 h-14 rounded-full flex items-center justify-center cursor-pointer">
            <IoMdCall className='text-2xl mx-auto' />
          </div>
          <div onClick={handleWhatsAppClick} className=" bg-green-500 text-white w-14 h-14 rounded-full flex items-center justify-center cursor-pointer">
            <BsWhatsapp className='text-2xl mx-auto' />
          </div>
        </div>
      </section>
      <section className="py-20 px-2">
        <div className="container mx-auto md:pl-5">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full px-2 sm:px-0 lg:w-[60%] mt-12 lg:mt-0 ">
              <p className="text-blackn font-bold domine" data-aos="fade-right">
                Welcome to Advilite
              </p>
              <h2
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic"
                data-aos="fade-right"
              >
                We Will Provide the Best Business Solutions
              </h2>
              <p
                className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                ADVILITE is a dynamic company with a forward-thinking approach
                that sets us apart in the industry. We are driven by a
                commitment to delivering exceptional services tailored precisely
                to your unique business needs. Our solid foundation in the field
                has allowed us to refine our expertise, providing unparalleled
                solutions that address your business's challenges.
              </p>
              <div className="flex flex-col sm:flex-row justify-around  mt-4">
                <ul data-aos="fade-up">
                  <li className="flex items-center my-2 text-[#0A3242] hover:text-greenn hover:cursor-pointer text-base sm:text-lg md:text-base lg:text-lg domine">
                    <Link to="/professional-firm">
                      <FontAwesomeIcon icon={faPeopleGroup} className="mx-2" />
                      Systems Software in Dubai
                    </Link>
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] hover:text-greenn hover:cursor-pointer text-base sm:text-lg md:text-base lg:text-lg domine">
                    <Link to="/business-consultancy">
                      <FontAwesomeIcon icon={faLightbulb} className="mx-2" />
                      Database Management in Dubai
                    </Link>
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] hover:text-greenn hover:cursor-pointer text-base sm:text-lg md:text-base lg:text-lg domine">
                    <Link to="/corporate-finance">
                      <FontAwesomeIcon icon={faChartPie} className="mx-2" />
                      Corporate Services in Dubai
                    </Link>
                  </li>

                  <li className="flex items-center my-2 text-[#0A3242] hover:text-greenn hover:cursor-pointer text-base sm:text-lg md:text-base lg:text-lg domine">
                    <Link to="/outsourcing-cfo">
                      <FontAwesomeIcon icon={faUserTie} className="mx-2" />
                      Tax Consultation in Dubai
                    </Link>
                  </li>

                  <li className="flex items-center my-2 text-[#0A3242] hover:text-greenn hover:cursor-pointer text-base sm:text-lg md:text-base lg:text-lg domine">
                    <Link to="/government-incentives">
                      <FontAwesomeIcon icon={faHandshake} className="mx-2" />
                      Company formation in Dubai
                    </Link>
                  </li>
                </ul>
                <ul data-aos="fade-up">
                  <li className="flex items-center my-2 text-[#0A3242] hover:text-greenn hover:cursor-pointer text-base sm:text-lg md:text-base lg:text-lg domine">
                    <Link to="/gulf-corporate-tax">
                      <FontAwesomeIcon
                        icon={faFileSignature}
                        className="mx-2"
                      />
                      Pro Services in Dubai
                    </Link>
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] hover:text-greenn hover:cursor-pointer text-base sm:text-lg md:text-base lg:text-lg domine">
                    <Link to="/vat-accounting-services">
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        className="mx-2"
                      />
                      Golden visa in Dubai
                    </Link>
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] hover:text-greenn hover:cursor-pointer text-base sm:text-lg md:text-base lg:text-lg domine">
                    <Link to="/investor-visa">
                      <FontAwesomeIcon icon={faPassport} className="mx-2" />
                      Corporate Finance in Dubai
                    </Link>
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] hover:text-greenn hover:cursor-pointer text-base sm:text-lg md:text-base lg:text-lg domine">
                    <Link to="/advisory-bookkeeping">
                      <FontAwesomeIcon icon={faBook} className="mx-2" />
                      General Trading in Dubai
                    </Link>
                  </li>

                  <li className="flex items-center my-2 text-[#0A3242] hover:text-greenn hover:cursor-pointer text-base sm:text-lg md:text-base lg:text-lg domine">
                    <Link to="/transaction-services">
                      <FontAwesomeIcon
                        icon={faHandHoldingUsd}
                        className="mx-2"
                      />
                      Business setup in Dubai
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full px-2 sm:px-0 lg:w-[40%]">
              <form
                onSubmit={submitfunction}
                className="p-2 lg:p-10 w-full sm:w-[90%] mx-auto block shadow-lg py-8 md:py-20 bg-white mt-[-8rem] lg:mt-[-20rem]"
                data-aos="zoom-in"
              >
                <p className="text-blacknn font-bold domine">SEND A MESSAGE</p>
                <h2 className="text-2xl sm:text-3xl lg:text-4xl xl:text-5xl text-greenn py-4 font-bold centurygothic">
                  Request a call back
                </h2>
                <p className="text-black bitter my-1 domine">Full Name</p>
                <input
                  type="text"
                  required
                  name="name"
                  value={inputs.name || ""}
                  onChange={handleChange}
                  // onChange={(e) => setvari({ ...vari, name: e.target.value })}
                  className=" my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                />
                <p className="text-black bitter my-1 domine">Email Address</p>
                <input
                  type="email"
                  required
                  name="email"
                  value={inputs.email || ""}
                  onChange={handleChange}
                  // onChange={(e) => setvari({ ...vari, email: e.target.value })}
                  className=" my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                />
                <p className="text-black bitter my-1 domine">Phone Number</p>
                <input
                  type="number"
                  required
                  name="phone"
                  value={inputs.phone || ""}
                  onChange={handleChange}
                  // onChange={(e) => setvari({ ...vari, phone: e.target.value })}
                  className=" my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                />
                <p className="text-black bitter my-1 domine">
                  Additional Details
                </p>
                <textarea
                  rows="3"
                  cols="50"
                  name="message"
                  value={inputs.message || ""}
                  onChange={handleChange}
                  // onChange={(e) =>
                  //   setvari({ ...vari, message: e.target.value })
                  // }
                  className=" my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                />
                <button
                  type="submit"
                  className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl"
                >
                  Get a Quote
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 gradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <h2
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-[#9bff9b] font-bold py-4 centurygothic"
                data-aos="fade-right"
              >
                Why Choose a Advilite?{" "}
              </h2>
              <p
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                Advilite is a leading consulting service that offers a wide
                range of tax-related solutions to individuals, small businesses,
                and large corporations. We can provide effective solutions for
                your tax concerns. At Advilite, we believe in providing our
                clients with a personalized approach. Our specialists will
                collaborate closely with you to guarantee you receive the best
                results.
              </p>
              <p
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                Advilite offers a wide range of services financing (both long-
                and short-term), transaction advisory, contract structuring, VAT
                registration, Business Consultancy, Advisory & Bookkeeping
                Assistance, Tax Compliances and Advice for Direct and Indirect
                Taxes, Corporate Finance, Outsourcing CFO Function, Transaction
                Service, Government Incentive and Subsidies, GCC Gulf Corporate
                Tax, and compliance.
              </p>
            </div>
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={whychoose}
                className="mx-auto block"
                alt="Advilite why choose"
                data-aos="zoom-in"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="text-center w-full px-2 py-20 bg-stackingplat bg-no-repeat bg-contain">
        <div className="container mx-auto block">
          <p className="text-greenn font-bold domine">Our Services</p>
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center text-blackn py-4 w-full md:w-3/4 mx-auto block font-semibold centurygothic">
            What We Provide
          </h2>
          <div className="flex justify-center flex-wrap">
            <div className="p-2 w-full md:w-1/2 lg:w-1/4">
              <div
                className="bg-[#e0ffe0] hover:bg-[#9bff9b] hover:cursor-pointer h-full rounded-xl"
                data-aos="zoom-in-up"
              >
                <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                  <h2 className="w-full text-center centurygothic font-bold text-xl">
                    Audit & Assurance services
                  </h2>
                </div>
                <div className={`py-2 px-4 domine text-[14px] text-justify`}>
                  Advilite is a trusted name in the auditing industry, providing
                  professional auditing services in Dubai, UAE. Our team of
                  professionals is equipped with the required information,
                  abilities, and ability to provide precise and trustworthy
                  audits for companies of all sizes. With a strong focus on
                  professionalism, our auditing services are not just about
                  numbers. We take a holistic approach, considering perspectives
                  from management, financial users, and stakeholders to provide
                  a comprehensive analysis of your financial statements.
                </div>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/2 lg:w-1/4">
              <div
                className="bg-[#e0ffe0] hover:bg-[#9bff9b] hover:cursor-pointer h-full rounded-xl"
                data-aos="zoom-in-up"
              >
                <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                  <h2 className="w-full text-center centurygothic font-bold text-xl">
                    Accounting & Financing services
                  </h2>
                </div>
                <div className={`py-2 px-4 domine text-[14px] text-justify`}>
                  Advilite provides businesses in Dubai, UAE, with high-quality
                  accounting and bookkeeping services and financial consulting
                  solutions. Our services cover various facets of financial
                  management, including capital budgeting, investment risk
                  analysis, financial planning, and working capital management
                  guidance. We recognize that each business has distinct
                  financial requirements, so we offer customized solutions that
                  meet your company's specific needs. Whether your business is a
                  small startup or a large corporation, our team of experienced
                  financial consultants is dedicated to guiding your company
                  toward profitability and success.
                </div>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/2 lg:w-1/4">
              <div
                className="bg-[#e0ffe0] hover:bg-[#9bff9b] hover:cursor-pointer h-full rounded-xl"
                data-aos="zoom-in-up"
              >
                <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                  <h2 className="w-full text-center centurygothic font-bold text-xl">
                    Business Setup
                  </h2>
                </div>
                <div className={`py-2 px-4 domine text-[14px] text-justify`}>
                  It can be an exciting yet daunting prospect if you're
                  considering starting a business in Dubai or any of the
                  Emirates. However, it can be as straightforward as it sounds
                  with the proper support and guidance. That's where Advilite
                  comes in - as a leading business setup consultant, we can
                  provide the assistance and expertise you need to get your
                  company off the ground. Dubai offers many options for
                  entrepreneurs looking to set up a business in the UAE. Here
                  are some of the most popular types of company formation
                  available in Dubai:
                </div>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/2 lg:w-1/4">
              <div
                className="bg-[#e0ffe0] hover:bg-[#9bff9b] hover:cursor-pointer h-full rounded-xl"
                data-aos="zoom-in-up"
              >
                <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                  <h2 className="w-full text-center centurygothic font-bold text-xl">
                    Corporate Tax Services
                  </h2>
                </div>
                <div className={`py-2 px-4 domine text-[14px] text-justify`}>
                  Advilite is a leading tax consultant firm in Dubai, offering a
                  comprehensive range of corporate tax consultancy services,
                  including VAT return filings, registration, training, and
                  transaction advisory services. As the implementation of
                  corporate tax approaches 2023, the importance and urgency of
                  our services are steadily increasing. Our firm has the skills
                  to handle these services efficiently, helping businesses
                  comply with tax laws and avoid financial losses.
                </div>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/2 lg:w-1/4">
              <div
                className="bg-[#e0ffe0] hover:bg-[#9bff9b] hover:cursor-pointer h-full rounded-xl"
                data-aos="zoom-in-up"
              >
                <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                  <h2 className="w-full text-center centurygothic font-bold text-xl">
                    Consultant Services
                  </h2>
                </div>
                <div className={`py-2 px-4 domine text-[14px] text-justify`}>
                  Advilite is a leading tax consulting firm that provides a wide
                  range of consulting services to help businesses achieve their
                  goals. Our team of experts understands the complexities of
                  business management, and we offer customized solutions to
                  optimize your business operations. Here's how we can help your
                  business grow and succeed in the key areas. Our experienced
                  consultants will work closely with you to understand your
                  needs and develop a comprehensive plan tailored to your
                  situation.
                </div>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/2 lg:w-1/4">
              <div
                className="bg-[#e0ffe0] hover:bg-[#9bff9b] hover:cursor-pointer h-full rounded-xl"
                data-aos="zoom-in-up"
              >
                <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                  <h2 className="w-full text-center centurygothic font-bold text-xl">
                    Expert VAT Services{" "}
                  </h2>
                </div>
                <div className={`py-2 px-4 domine text-[14px] text-justify`}>
                  As a business owner in Dubai and the UAE, you understand the
                  importance of complying with the region's Value Added Tax
                  (VAT) laws and regulations. However, navigating the complex
                  VAT landscape can be challenging, which is where our firm
                  comes in. Our firm offers top-notch VAT services to help your
                  business comply easily with VAT laws and regulations. Our team
                  of experienced VAT professionals has in-depth knowledge of the
                  VAT laws and regulations in Dubai and the UAE, ensuring your
                  business stays fully compliant.
                </div>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/2 lg:w-1/4">
              <div
                className="bg-[#e0ffe0] hover:bg-[#9bff9b] hover:cursor-pointer h-full rounded-xl"
                data-aos="zoom-in-up"
              >
                <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                  <h2 className="w-full text-center centurygothic font-bold text-xl">
                    Amazon Listing
                  </h2>
                </div>
                <div className={`py-2 px-4 domine text-[14px] text-justify`}>
                  At Advilite, we understand the power of a well-crafted Amazon listing. It's not just about listing your products; it's about creating an online shopping experience that converts browsers into buyers.With our Amazon Listing Service, you can free up valuable time and resources while enjoying the peace of mind that your listings are in expert hands. Elevate your Amazon presence, boost your sales, and conquer the e-commerce world with our proven listing service.
                </div>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/2 lg:w-1/4">
              <div
                className="bg-[#e0ffe0] hover:bg-[#9bff9b] hover:cursor-pointer h-full rounded-xl"
                data-aos="zoom-in-up"
              >
                <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                  <h2 className="w-full text-center centurygothic font-bold text-xl">
                    Website development service
                  </h2>
                </div>
                <div className={`py-2 px-4 domine text-[14px] text-justify`}>
                  Advilite is more than just a website development service; we're your digital partners. We're passionate about helping businesses thrive online, and we bring a wealth of expertise to every project.Website is often the first interaction potential customers have with your business. It's not just a website; it's your online storefront, your brand's digital ambassador, and a powerful tool for growth.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 bg-mainbusinessconslt bg-blackn bg-cover bg-no-repeat bg-center">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-white font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center">
            How Can Our Business Consultant Helps You ?
          </h2>
          <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-center">
            We remove the bureaucracy and complexity with company setup,
            enabling business to be operational as soon as possible
          </p>
          <div className="grid grid-cols-1 gap-4 md:gap-8 lg:gap-12 sm:grid-cols-3 mt-8">
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faUser}
                className="text-[#9bff9b] text-3xl text-center mx-auto block"
              />
              <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-center">
                Expert Consultation
              </p>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white text-3xl text-center mt-8 sm:mt-0 mx-auto transform rotate-90 sm:rotate-0 block sm:absolute sm:top-[20%] sm:right-[-1.4rem] md:top-[30%] md:right-1"
              />
            </div>
            <div className="p-4 relative" data-aos="zoom-in">
              <FontAwesomeIcon
                icon={faChartBar}
                className="text-[#9bff9b] text-3xl text-center mx-auto block"
              />
              <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-center">
                Business Review and Strategy
              </p>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white text-3xl text-center mt-8 sm:mt-0 mx-auto transform rotate-90 sm:rotate-0 block sm:absolute sm:top-[20%] sm:right-[-1.4rem] md:top-[30%] md:right-1"
              />
            </div>
            <div className="p-4" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faListCheck}
                className="text-[#9bff9b] text-3xl text-center mx-auto block"
              />
              <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-center">
                Business Licence, <br />
                Bank Account Opening & <br />
                Obtaining Visas
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-center w-full px-2 py-20 bg-stackingplat bg-no-repeat bg-contain">
        <div className="container mx-auto block">
          <p className="text-blackn font-bold domine">Our Team</p>
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center text-greenn py-4 w-full md:w-3/4 mx-auto block font-semibold centurygothic">
            Meet the Experts{" "}
          </h2>
          {/* <TeamSlider /> */}
          <div className="flex justify-around items-center">
            <div className="p-4 bg-lightgreen rounded-xl">
              <img
                src={member1}
                alt="team members"
                className="mx-auto block filter drop-shadow-[4px_4px_6px_#010101] hover:drop-shadow-[-4px_4px_6px_#010101] hover:cursor-pointer hover:transition-shadow"
              />
              <p className="domine rounded-xl text-base text-center sm:text-lg lg:text-xl px-1 mt-4 text-greenn">
                Piyush Sangoi
              </p>
            </div>
            <div className="p-4 bg-lightgreen rounded-xl">
              <img
                src={member2}
                alt="team members"
                className="mx-auto block filter drop-shadow-[4px_4px_6px_#010101] hover:drop-shadow-[-4px_4px_6px_#010101] hover:cursor-pointer hover:transition-shadow"
              />
              <p className="domine rounded-xl text-base text-center sm:text-lg lg:text-xl px-1 mt-4 text-greenn">
                Parin Savla
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 revgradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col px-4 md:px-4 lg:px-0 lg:flex-row">
            <div className="w-full lg:w-1/2 py-12 px-4 md:px-4 bg-white shadow-md shadow-greenn lg:px-10">
              <h2
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl text-greenn font-bold py-4 centurygothic"
                data-aos="fade-right"
              >
                Mission
              </h2>
              <p
                className="text-greens bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-right"
              >
                When dealing with our clients, we adopt a collaborative style,
                emphasizing establishing enduring relationships built on respect
                and trust. We spend the effort researching your company, its
                opportunities, and challenges because we recognize that every
                client has specific requirements. Thanks to this, we can now
                provide specialized solutions tailored to your needs.
              </p>
            </div>
            <div className="w-full lg:w-1/2 py-12 px-4 md:px-4 lg:px-10">
              <h2
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl text-[#9bff9b] font-bold py-4 centurygothic"
                data-aos="fade-up"
              >
                Vision
              </h2>
              <p
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                The Advilite vision for taxation is to promote economic growth
                and diversification by attracting foreign investment,
                encouraging entrepreneurship, and supporting small and
                medium-sized enterprises (SMEs). The mission is to achieve this
                goal through the implementation of a value-added tax (VAT)
                system that is simple, transparent, and business-friendly. The
                VAT system creates a favorable business environment while
                promoting compliance and fairness.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="industry"
        className="py-8 md:py-20 bg-no-repeat bg-cover bg-center"
      >
        <h2
          id="headingindustry"
          className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center text-greenn py-4 w-full md:w-3/4 mx-auto block font-semibold centurygothic mb-8"
        >
          Industries
        </h2>
        <div className="block lg:hidden">
          <div className="container mx-auto block">
            <div className="flex justify-center items-center">
              <div className="">
                <div className="bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov">
                  <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                    &nbsp;
                  </h2>
                </div>
                <div
                  id="mindustry1"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faSolarPanel}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Energy
                    </h2>
                  </div>
                </div>
                <div
                  id="mindustry2"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faDroplet}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Water
                    </h2>
                  </div>
                </div>
                <div
                  id="mindustry3"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faBicycle}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Mobility
                    </h2>
                  </div>
                </div>
                <div
                  id="mindustry4"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faPhotoFilm}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Movies
                    </h2>
                  </div>
                </div>
                <div
                  id="mindustry5"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faUtensils}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Food
                    </h2>
                  </div>
                </div>
                <div className="bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov">
                  <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                    &nbsp;
                  </h2>
                </div>
              </div>
              <div className="">
                <div className="bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov">
                  <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                    &nbsp;
                  </h2>
                </div>
                <div
                  id="mindustry6"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faScrewdriverWrench}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Manufacture
                    </h2>
                  </div>
                </div>
                <div
                  id="mindustry7"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faPlay}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Media
                    </h2>
                  </div>
                </div>
                <div
                  id="mindustry8"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faPlane}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Tourism
                    </h2>
                  </div>
                </div>
                <div
                  id="mindustry9"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faFootball}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Sports
                    </h2>
                  </div>
                </div>
                <div className="bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov">
                  <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                    &nbsp;
                  </h2>
                </div>
              </div>
              <div className="">
                <div className="bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov">
                  <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                    &nbsp;
                  </h2>
                </div>
                <div
                  id="mindustry10"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faLayerGroup}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Design
                    </h2>
                  </div>
                </div>
                <div
                  id="mindustry11"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faGraduationCap}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Education
                    </h2>
                  </div>
                </div>
                <div
                  id="mindustry12"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faStarOfLife}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Healthcare
                    </h2>
                  </div>
                </div>
                <div
                  id="mindustry13"
                  className="hover:bg-lightgreen hover:text-greenn bg-lightgreen m-1 text-greenn w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl text-greenn"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic text-greenn">
                      Finance
                    </h2>
                  </div>
                </div>
                <div
                  id="mindustry14"
                  className="hover:bg-lightgreen hover:text-greenn bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faMicrochip}
                      className="mx-auto block text-2xl sm:text-4xl md:text-5xl"
                    />
                    <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                      Technical
                    </h2>
                  </div>
                </div>
                <div className="bg-blackn m-1 text-white w-[95px] h-[95px] sm:w-[140px] sm:h-[140px] md:w-[200px] md:h-[200px] rounded-full flex justify-center items-center transonhov">
                  <h2 className="text-sm sm:text-xl md:text-2xl centurygothic">
                    &nbsp;
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block">
          <div className="flex justify-center items-center">
            <div className=" transonhov bg-blackn rounded-full mx-1 circle"></div>
            <div
              id="industry1"
              className="transonhov bg-blackn rounded-full mx-1 circle flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faSolarPanel}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Energy
                </h2>
              </div>
            </div>
            <div
              id="industry2"
              className="transonhov bg-blackn rounded-full mx-1 circle flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faDroplet}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Water
                </h2>
              </div>
            </div>
            <div
              id="industry3"
              className="transonhov bg-blackn rounded-full mx-1 circle flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faBicycle}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Mobility
                </h2>
              </div>
            </div>
            <div
              id="industry4"
              className="transonhov bg-blackn rounded-full mx-1 circle flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faPhotoFilm}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Movies
                </h2>
              </div>
            </div>
            <div
              id="industry5"
              className="transonhov bg-blackn rounded-full mx-1 circle flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faUtensils}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Food
                </h2>
              </div>
            </div>
            <div className="transonhov bg-blackn rounded-full mx-1 circle"></div>
          </div>
          <div className="flex justify-center items-center mt-2">
            <div className="transonhov w-[150px] h-[250px] halfcircle rounded-tr-full rounded-br-full bg-blackn"></div>
            <div className="transonhov w-[250px] h-[250px] circle bg-blackn rounded-full mx-1"></div>
            <div
              id="industry6"
              className="transonhov w-[250px] h-[250px] circle bg-blackn rounded-full mx-1 flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faScrewdriverWrench}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Manufacture
                </h2>
              </div>
            </div>
            <div
              id="industry7"
              className="transonhov w-[250px] h-[250px] circle bg-blackn rounded-full mx-1 flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faPlay}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Media
                </h2>
              </div>
            </div>
            <div
              id="industry8"
              className="transonhov w-[250px] h-[250px] circle bg-blackn rounded-full mx-1 flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faPlane}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Tourism
                </h2>
              </div>
            </div>
            <div
              id="industry9"
              className="transonhov w-[250px] h-[250px] circle bg-blackn rounded-full mx-1 flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faFootball}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Sport
                </h2>
              </div>
            </div>
            <div className="transonhov w-[250px] h-[250px] circle bg-blackn rounded-full mx-1"></div>
            <div className="transonhov w-[250px] h-[250px] circle bg-blackn rounded-full mx-1"></div>
          </div>
          <div className="flex justify-center items-center">
            <div className="transonhov circle bg-blackn rounded-full mx-1"></div>
            <div
              id="industry10"
              className="transonhov circle bg-blackn rounded-full mx-1 flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faGasPump}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Petroleum
                </h2>
              </div>
            </div>
            <div
              id="industry11"
              className="transonhov circle bg-blackn rounded-full mx-1 flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faGraduationCap}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Education
                </h2>
              </div>
            </div>
            <div
              id="industry12"
              className="transonhov circle bg-blackn rounded-full mx-1 flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faStarOfLife}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Healthcare
                </h2>
              </div>
            </div>
            <div
              id="industry13"
              className="transonhov circle bg-lightgreen hover:bg-lightgreen rounded-full mx-1 flex justify-center items-center text-greenn hover:cursor-pointer"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faDollarSign}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block text-greenn"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center text-greenn">
                  Finance
                </h2>
              </div>
            </div>
            <div
              id="industry14"
              className="transonhov circle bg-blackn rounded-full mx-1 flex justify-center items-center text-white hover:cursor-pointer hover:bg-lightgreen hover:text-greenn hover:scale-110"
            >
              <div className="w-full">
                <FontAwesomeIcon
                  icon={faMicrochip}
                  className="text-base sm:text-md lg:text-3xl xl:text-3xl 2xl:text-5xl mx-auto block"
                />
                <h2 className="text-base md:text-[12px] lg:text-base xl:text-xl centurygothic w-full md:w-3/4 mx-auto block text-center">
                  Technical
                </h2>
              </div>
            </div>
            <div className="transonhov circle bg-blackn rounded-full mx-1"></div>
          </div>
        </div>
      </section>
      <section className="bg-contactus bg-cover bg-no-repeat bg-center bg-fixed">
        <div className="bg-[#184341] py-24 bg-opacity-60">
          <div className="container mx-auto block">
            <h2
              className="text-2xl my-6 sm:text-2xl lg:text-3xl xl:text-4xl text-white font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center"
              data-aos="fade-right"
            >
              Still Confused About Our Features?
            </h2>
            <h2
              className="text-2xl my-6 sm:text-2xl lg:text-3xl xl:text-4xl text-[#9bff9b] font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center"
              data-aos="fade-up"
            >
              Get A Consultation
            </h2>
            <p
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-center"
              data-aos="fade-up"
            >
              Our tax consultants are tax law experts who guide individuals and
              businesses through the complex tax system. We specialize in
              minimizing tax liabilities and maximizing savings for our clients
            </p>
            <Link
              to="/contact-us"
              className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-greenn mx-auto block w-max mt-6"
              data-aos="fade-up"
            >
              Get Started
            </Link>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl text-center lg:text-3xl xl:text-4xl text-greenn font-bold py-4 centurygothic">
            FAQ's
          </h2>
          <FaqHome acco={items} />
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Home;