import React, { useState, useEffect } from 'react'
import logo from '../img/taxhub-logo-150x150.webp';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const [vari, setVari] = useState([]);
      const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handlechange = e => {
        const name = e.target.name;
        const value = e.target.value;
        setVari( values => ({ ...values, [name]:value }) )
    }

    // useEffect(() => {
    //     if( localStorage.getItem('JWT') ) {
    //         navigate('/dashboard/blogs');
    //     }
    // }, []);

     const submtfunction = async (e) => {
       e.preventDefault();
       setLoading(true);

       try {
         const response = await axios.post(
           "https://api.stashack.cloud:3000/private/login",
           vari
         );

         const res = response?.data;
         if (res.Success == "Logged in successfully!") {
           toast.success(res.Success);
           localStorage.setItem("JWT", response.data.JWT);
           console.log(response.data.JWT,"fhgfh");
           setVari([]);
           window.location.href = "/dashboard/blogs";
           //  if (res.Success === 200) {
           //    navigate("/admin/blogs");
           //  }
         } else {
           toast.error(res.Error);
         }
       } catch (error) {
         console.error(error);
       }

       setLoading(false);
     };


    // const submtfunction = async (e) => {
    //     e.preventDefault();
    //     await axios
    //     .post('https://api.taxhub.ae/api/login', vari)
    //     .then( res => {
    //         localStorage.setItem('id',JSON.stringify(res.data.id));
    //         localStorage.setItem('access_token',JSON.stringify(res.data.access_token));
    //         if( res.status === 200 ) {
    //             navigate('/dashboard/blogs');
    //         }
    //     } )
    //     .catch( err => {
    //         toast.error('Invalid Credentials')
    //     } )
    // }

  return (
    <>
      <section className="h-full md:h-screen bg-accfinance bg-cover bg-no-repeat bg-center">
        <div className="pt-[3.6rem] lg:pt-20 h-full px-2">
          <div className="container mx-auto block h-full py-16 md:py-0">
            <div className="flex items-center h-full">
              <form
                onSubmit={submtfunction}
                className="py-16 px-2 lg:px-10 w-full sm:w-[90%] md:w-3/4 lg:w-1/2 xl:w-[40%] mx-auto block shadow-lg py-8 md:py-20 bg-[#e0ffe09c]"
              >
                <img src={logo} alt="advilite logo" className="mx-auto block" />
                <h2 className="text-2xl sm:text-3xl lg:text-4xl xl:text-5xl text-greenn py-4 font-bold centurygothic pb-8 text-center">
                  Sign In Your Account
                </h2>
                <p className="text-black bitter my-1 domine">User Name</p>
                <input
                  type="email"
                  name="email"
                  value={vari.email || ""}
                  onChange={handlechange}
                  required
                  className=" my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black bitter"
                />
                <p className="text-black bitter my-1 domine">Password</p>
                <input
                  type="password"
                  name="password"
                  value={vari.password || ""}
                  onChange={handlechange}
                  required
                  className=" my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black bitter"
                />
                <button
                  type="submit"
                  className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:bg-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-greenn mt-8 mx-auto block"
                >
                  Sign In
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;