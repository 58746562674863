import { faEnvelope, faMapLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import whychoose from '../img/contactus/taxhub-we-are.webp';
import React, { useState, useEffect } from 'react'
import Footer from './Footer'
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const ContactUs = () => {

  const [vari, setvari] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });

  const handlechange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setvari( values => ({ ...values, [name]: value }) )
  }

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  };

  const submitfunction = async (e) => {
    e.preventDefault();

    //  var formData = new FormData();
    //  formData.append("jwt", jwt);
    //  formData.append("Name", "");
    //  formData.append("Email", "");
    //  formData.append("Profile_Image", image);

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "contact",
      data: vari,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res?.Success) {
          setvari(res?.Success);
          toast.success(res?.Success);
        } else {
          toast.error(res?.Error);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  // const submitfunction = (e) => {
  //   e.preventDefault();
  //   axios
  //     .post("https://api.taxhub.ae/api/contact-us", vari, config)
  //     .then((response) => {
  //       if (response?.data?.Success) {
  //         toast.success(response?.data?.Success);
  //         setvari([]);
  //       } else {
  //         toast.error("Something Went Wrong !!!");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       toast.error("Something Went Wrong !!!");
  //     });
  // };

  // const submitfunction = (e) => {

  //   e.preventDefault();
  //   axios.post('https://api.taxhub.ae/api/contact-us', vari)
  //   .then(response => {
  //     if( response.data.message === 'Contact successfully' ) {
  //       toast.success('Response Submitted !!!');
  //       setvari([]);
  //     }
  //     else {
  //       toast.error('Something Went Wrong !!!');
  //     }
  //   })
  //   .catch(error => {
  //     console.error(error);
  //     toast.error('Something Went Wrong !!!');
  //   });

  // }

  useEffect( () => {

    Aos.init({ duration: 1500 });

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

  },[] );

  return (
    <>
      <Helmet>
        <title>Advilite | Contact Us | Tax compliance services in UAE</title>
        <meta
          name="title"
          content="Advilite | Contact Us | Tax compliance services in UAE"
        />
        <meta
          name="description"
          content="Whether you have a question or need assistance with our services, You can contact us by phone or email or by filling out the contact form on our website. "
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://advilite.com/contact-us" />
        <meta
          property="og:title"
          content="Advilite | Contact Us | Tax compliance services in UAE"
        />
        <meta property="og:site_name" content="advilite.com" />
        <meta property="og:url" content="https://advilite.com/contact-us" />
        <meta
          property="og:description"
          content="Whether you have a question or need assistance with our services, You can contact us by phone or email or by filling out the contact form on our website. "
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://advilite.com/static/media/taxhub-we-are.745c7b5cad8e4a9d3bd2.webp"
        />
      </Helmet>

      <section className="py-16  bg-blackn">
        <div className="container mx-auto block ">
          <div className="flex flex-col-reverse lg:flex-row justify-center items-center">
            <div className="w-full lg:w-1/2 px-2">
              <p
                data-aos="fade-right"
                className="text-hovergreen bitter text-base sm:text-lg md:text-base lg:text-lg domine mx-auto block w-full mt-8 lg:mt-0"
              >
                Contact Us
              </p>
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-white font-bold py-4 centurygothic"
              >
                We are always ahead for providing the best Professional
                Solutions to you
              </h2>
              <form
                data-aos="fade-up"
                onSubmit={submitfunction}
                className="p-2 lg:p-10 w-full mx-auto block shadow-lg py-8 md:py-20"
              >
                <p className="text-white bitter my-1 domine">Full Name</p>
                <input
                  type="text"
                  name="name"
                  value={vari.name || ""}
                  onChange={handlechange}
                  required
                  className=" my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-white bitter"
                />
                <p className="text-white bitter my-1 domine">Email Address</p>
                <input
                  type="email"
                  name="email"
                  value={vari.email || ""}
                  onChange={handlechange}
                  required
                  className=" my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-white bitter"
                />
                <p className="text-white bitter my-1 domine">Phone Number</p>
                <input
                  type="number"
                  name="phone"
                  value={vari.phone || ""}
                  onChange={handlechange}
                  required
                  // inputMode="numeric"
                  className=" my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-white bitter"
                />
                <p className="text-white bitter my-1 domine">Message</p>
                <textarea
                  name="message"
                  value={vari.message || ""}
                  onChange={handlechange}
                  rows="3"
                  cols="50"
                  className=" my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-white bitter"
                />
                <button
                  type="submit"
                  className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl"
                >
                  Submit
                </button>
              </form>
            </div>
            <div className="w-full lg:w-1/2 px-2">
              <img
                src={whychoose}
                className="mx-auto block"
                alt="advilite why choose"
                data-aos="zoom-in-up"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="h-full bg-contactus bg-cover bg-no-repeat bg-center">
        <div className="pt-[3.6rem] lg:pt-20 h-full px-2">
          <div className="container mx-auto block h-full pb-40 lg:py-40">
            <div className="flex items-center h-full w-full">
              <div className="w-full">
                <h2
                  data-aos="fade-down"
                  className="text-hovergreen text-3xl sm:text-5xl text-center md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold md:pl-4 lg:pl-6 mb-6 centurygothic w-full"
                >
                  Contact Us
                </h2>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 sm:gap-2 md:gap-4 lg:gap-12 mb-[-14rem]">
                  <div
                    className="px-4 py-8 bg-white rounded-xl"
                    data-aos="fade-up"
                  >
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="text-3xl text-greenn mr-4"
                      />
                      <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic">
                        Phone
                      </h2>
                    </div>
                    <a
                      className="text-blackn bitter text-base md:text-base lg:text-lg domine mx-auto block w-full text-center"
                      href="tel:+971 52 112 2777"
                    >
                      (+971) 52 11 22 777
                    </a>
                  </div>
                  <div
                    className="px-4 py-8 bg-white rounded-xl"
                    data-aos="fade-up"
                  >
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={faMapLocationDot}
                        className="text-3xl text-greenn mr-4"
                      />
                      <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic">
                        Address
                      </h2>
                    </div>
                    <p className="px-0 lg:px-8 text-blackn bitter text-base md:text-base lg:text-lg domine mx-auto block w-full text-center">
                      Business Center 1, M Floor, The Meydan Hotel, Nad AI
                      Sheba, Dubai, U.A.E.
                    </p>
                  </div>
                  <div
                    className="px-4 py-8 bg-white rounded-xl"
                    data-aos="fade-up"
                  >
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="text-3xl text-greenn mr-4"
                      />
                      <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic">
                        Email
                      </h2>
                    </div>
                    <a
                      className="text-blackn bitter text-base md:text-base lg:text-lg domine mx-auto block w-full text-center"
                      href="mailto:info@advilite.com"
                    >
                      info@advilite.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ContactUs;


// "react": "^18.2.0",
// "react-dom": "^18.2.0",
// "react-helmet": "^6.1.0",