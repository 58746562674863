import whychoose from '../img/taxhub-ourteam.webp';
import React, { useEffect } from 'react';
import piush from '../img/team/2.webp';
import parin from '../img/team/3.webp';

import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

const OurTeam = () => {

  useEffect( () => {

    Aos.init({ duration: 1500 });

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

  },[] );

  return (
    <>
      <Helmet>
        <title>
          Advilite | Our Team | Tax consulting services UAE | Tax UAE
        </title>
        <meta
          name="title"
          content="Advilite | Our Team | Tax consulting services UAE | Tax UAE"
        />
        <meta
          name="description"
          content="Advilite professionals provide accurate audits and expert guidance to help you establish your business correctly, based on our extensive experience and knowledge."
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://advilite.com/our-team" />
        <meta
          property="og:title"
          content="Advilite | Our Team | Tax consulting services UAE | Tax UAE"
        />
        <meta property="og:site_name" content="advilite.com" />
        <meta property="og:url" content="https://advilite.com/our-team" />
        <meta
          property="og:description"
          content="Advilite professionals provide accurate audits and expert guidance to help you establish your business correctly, based on our extensive experience and knowledge."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://advilite.com/static/media/taxhub-ourteam.1358f17cf037b999e4c9.webp"
        />
      </Helmet>
      <section className="h-full">
        <div className="bg-white pb-20 lg:py-20 mt-20 bg-opacity-60 h-full px-2">
          <div className="container mx-auto block h-full md:py-0">
            <div className="flex items-center h-full">
              <div>
                <img
                  data-aos="zoom-in-up"
                  src={whychoose}
                  alt="advilite-business-consultancy"
                  className="mx-auto block w-full md:w-3/4 lg:w-[40%] rounded-xl"
                />
                <h2
                  data-aos="fade-up"
                  className="text-greenn text-3xl sm:text-5xl text-center md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold md:pl-4 lg:pl-6 mb-6 centurygothic mt-4"
                >
                  Our Team
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-base sm:text-lg md:text-base lg:text-lg text-blackn mb-8 domine text-justify w-full md:w-3/4 mx-auto block"
                >
                  Our team of professionals is well-equipped with the necessary
                  knowledge and expertise to provide accurate and reliable
                  audits. As an expert advisory service, we can help you
                  establish your business the right way by providing you with
                  valuable insights and guidance based on our extensive
                  experience and knowledge.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16">
        <div className="container mx-auto block">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="w-full lg:w-[30%] px-2 md:px-2">
              <img
                src={piush}
                data-aos="zoom-in-up"
                className="mx-auto block bg-white border-4 border-hovergreen rounded-full w-[70%] sm:w-1/2 md:w-[30%] lg:w-full 2xl:w-3/4"
                alt="advilite why choose"
              />
            </div>
            <div className="w-full lg:w-[70%] px-2 lg:px-4">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic text-center lg:text-start"
              >
                Piyush Sangoi (Founder Partner)
              </h2>
              <p
                data-aos="fade-up"
                className="text-blackn bitter mt-2 text-base domine text-justify md:mx-auto block md:text-center lg:text-justify"
              >
                He is a Founder Partner with 19 years of post-qualification
                experience, including eight years with various Big4 consulting
                firms like Deloitte, PwC, and KPMG. He practiced Central Excise,
                Customs, Service tax, State VAT, and Foreign Trade Policy during
                his tenure. In 2017, he resigned as an Associate Director of
                KPMG to assist various local and multinational corporations in
                migrating to the VAT regime in UAE. After returning to India, he
                founded Consultsure Management Services LLP and now works as an
                independent GST consultant, assisting Big4 and his clients.
              </p>
              <p
                data-aos="fade-up"
                className="text-blackn bitter mt-2 text-base domine text-justify md:mx-auto block md:text-center lg:text-justify"
              >
                Piyush has extensive experience transitioning clients to the
                newly introduced Goods & Services Tax (GST) from the erstwhile
                indirect tax regime. He helps clients assess the impact of GST
                on their business transactions, provides implementation support,
                and handholds clients in computing GST liabilities and filing
                periodical GST returns/refunds/LUT. He has also helped clients
                build relevant functional internal controls and report internal
                financial control as an audit procedure. Additionally, Piyush
                has undertaken various statutory/tax and internal audits,
                including Bank Branch Audits/investigations.
              </p>
              <p
                data-aos="fade-up"
                className="text-blackn bitter mt-2 text-base domine text-justify md:mx-auto block md:text-center lg:text-justify"
              >
                Piyush is an experienced trainer and has conducted various
                training sessions, seminars, and workshops in pre-GST and
                post-GST regimes to provide a better understanding of indirect
                taxes to attendees. He has also assisted clients in
                restructuring their businesses, identifying system support, and
                reporting requirements.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 bg-lightgreen">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-[70%] px-2 lg:px-4">
              <h2
                data-aos="fade-down"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic text-center lg:text-start"
              >
                Parin Saval (Founder Partner)
              </h2>
              <p
                data-aos="fade-up"
                className="text-blackn bitter mt-2 text-base domine text-justify md:mx-auto block md:text-center lg:text-justify"
              >
                He is a Partner Founder with over 15 years of post-qualification
                experience serving various local and foreign multinational
                clients in setting up their accounting and taxation framework.
                He has expertise in accountancy, MIS, and tax and regulatory
                environments for multiple clients.
              </p>
              <p
                data-aos="fade-up"
                className="text-blackn bitter mt-2 text-base domine text-justify md:mx-auto block md:text-center lg:text-justify"
              >
                He is experienced in establishing accounting frameworks in
                SAP/ERP for clients. He is skilled in creating a chart of
                accounts that considers the functional responsibilities of every
                part of the organization, such as AP, AR, GL, HR, and Admin. He
                regularly assists various clients in preparing financial
                statements that comply with the destination country's regulatory
                and reporting requirements. Parin is a good team player and can
                liaise with multiple functions to verify the sensitivity and
                validity of the information provided.
              </p>
              <p
                data-aos="fade-up"
                className="text-blackn bitter mt-2 text-base domine text-justify md:mx-auto block md:text-center lg:text-justify"
              >
                Parin has assisted clients in building relevant functional
                internal controls. He also helps clients report internal
                financial controls. Parin believes in automating the tax and
                accounting process. He regularly innovates technology solutions
                to ease tax compliance.
              </p>
            </div>
            <div className="w-full lg:w-[30%] px-2 md:px-2">
              <img
                data-aos="flip-right"
                src={parin}
                className="mx-auto block bg-white border-4 border-hovergreen rounded-full w-[70%] sm:w-1/2 md:w-[30%] lg:w-full 2xl:w-3/4"
                alt="advilite why choose"
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default OurTeam;