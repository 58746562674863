import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faBullhorn, faChartLine, faLaptopCode, faUsers } from '@fortawesome/free-solid-svg-icons';
import Footer from './Footer';
import $ from 'jquery';
import consultantser from '../img/consultantservices/taxhub-consultant-servies.webp';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const ConsultantServices = () => {

  useEffect( () => {

    Aos.init({ duration: 1500 });

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

  } );

  return (
    <>
      <Helmet>
        <title>
          Advilite | Consultant Service | Tax consulting service In UAE
        </title>
        <meta
          name="title"
          content="Advilite | Consultant Service | Tax consulting service In UAE"
        />
        <meta
          name="description"
          content="Our experienced tax consultants work closely with you to understand your needs and create a comprehensive plan to maximize profitability and minimize risk."
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://advilite.com/consultant-services" />
        <meta
          property="og:title"
          content="Advilite | Consultant Service | Tax consulting service In UAE"
        />
        <meta property="og:site_name" content="advilite.com" />
        <meta
          property="og:url"
          content="https://advilite.com/consultant-services"
        />
        <meta
          property="og:description"
          content="Our experienced tax consultants work closely with you to understand your needs and create a comprehensive plan to maximize profitability and minimize risk."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://advilite.com/static/media/taxhub-consultant-servies.f00257e542cb68e104af.webp"
        />
      </Helmet>
      <section className="lg:pt-18 bg-black">
        <div className="px-2 bg-white h-full py-20">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col-reverse md:flex-row justify-center items-center h-full">
              <div className="w-full md:w-1/2">
                <h2
                  className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic"
                  data-aos="fade-right"
                >
                  Consultant Services in Dubai, UAE
                </h2>
                <p
                  className="text-greens bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                  data-aos="fade-up"
                >
                  Maximizing Business Growth and Efficiency with Advilite
                  Dubai's Comprehensive Consulting Services.
                </p>
                <p
                  className="text-greens bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                  data-aos="fade-up"
                >
                  Advilite is a leading tax consulting firm that provides a wide
                  range of consulting services to help businesses achieve their
                  goals. Our team of experts understands the complexities of
                  business management, and we offer customized solutions to
                  optimize your business operations. Here's how we can help your
                  business grow and succeed in the key areas:
                </p>
                <p
                  className="text-greens bitter mt-2 mb-6 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                  data-aos="fade-up"
                >
                  Our consulting services are designed to help you identify
                  areas for improvement, streamline your operations, and achieve
                  your business objectives. Our experienced consultants will
                  work closely with you to understand your needs and develop a
                  comprehensive plan tailored to your situation. We'll help you
                  optimize your processes, minimize risk, and improve efficiency
                  to maximize profitability
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base sm:text-lg md:text-base lg:text-lg py-4 px-4 sm:px-10 text-white hover:text-greenn domine hover:bg-white hover:text-second m-0 border-2 border-greenn hover:border-greenn font_primary bg-greenn"
                  data-aos="fade-up"
                >
                  Reliable Tax Support
                </Link>
              </div>
              <div className="w-full md:w-1/2">
                <img
                  src={consultantser}
                  alt="advilite-business-consultancy"
                  className="mx-auto block w-3/4 rounded-xl"
                  data-aos="zoom-in-up"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-consultantser bg-cover bg-no-repeat bg-center ">
        <div className="py-20">
          <div className="container mx-auto block">
            <h2 className="text-2xl my-6 sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center">
              Here are some succeed key areas:
            </h2>
            <div className="flex justify-center flex-wrap">
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="flip-down"
              >
                <div className="h-full p-4">
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    className="text-white text-3xl text-center mx-auto block"
                  />
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-hovergreen font-bold text-center centurygothic">
                    Core Business Operations
                  </h2>
                  <p className="mt-2 text-white text-base domine text-justify">
                    At Advilite Dubai, we understand that managing your core
                    business operations effectively is essential for long-term
                    success. Our team of experts can help you optimize your
                    processes, improve productivity, and enhance your bottom
                    line. We offer various services, including project
                    management, supply chain logistics, risk management, and
                    compliance, to ensure your business runs smoothly and
                    efficiently.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="flip-down"
              >
                <div className="h-full p-4">
                  <FontAwesomeIcon
                    icon={faBullhorn}
                    className="text-white text-3xl text-center mx-auto block"
                  />
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-hovergreen font-bold text-center centurygothic">
                    Customer & Marketing
                  </h2>
                  <p className="mt-2 text-white text-base domine text-justify">
                    Your customers are the backbone of your business, and
                    effective marketing is essential for attracting and
                    retaining them. Advilite Dubai can help you develop a
                    comprehensive marketing strategy that drives results. Our
                    team of experts will conduct market research to understand
                    your target audience better, develop messaging that
                    resonates with them, and help you execute campaigns across
                    multiple channels. We'll also help you track and analyze
                    your results to ensure you get the best possible ROI.{" "}
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="flip-down"
              >
                <div className="h-full p-4">
                  <FontAwesomeIcon
                    icon={faLaptopCode}
                    className="text-white text-3xl text-center mx-auto block"
                  />
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-hovergreen font-bold text-center centurygothic">
                    Enterprise Technology & Performance
                  </h2>
                  <p className="mt-2 text-white text-base domine text-justify">
                    Staying ahead of the competition requires the right
                    technology. Advilite Dubai offers various IT services to
                    help you choose and implement the best solutions for your
                    business needs. Our team of experts has you covered
                    everything from system implementation and data management to
                    cybersecurity. We'll help you make informed decisions and
                    provide ongoing support to ensure optimal performance.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="flip-down"
              >
                <div className="h-full p-4">
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="text-white text-3xl text-center mx-auto block"
                  />
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-hovergreen font-bold text-center centurygothic">
                    Human Capital
                  </h2>
                  <p className="mt-2 text-white text-base domine text-justify">
                    Effective human capital management is essential for business
                    success; your employees are your most valuable asset.
                    Advilite Dubai can help you develop and implement HR
                    policies and procedures that attract and retain top talent.
                    We'll help you create job descriptions, develop employee
                    training programs, and design compensation and benefits
                    packages that keep your staff motivated and engaged. We'll
                    also assist with performance management and succession
                    planning to ensure you have the right people in the right
                    roles.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="flip-down"
              >
                <div className="h-full p-4">
                  <FontAwesomeIcon
                    icon={faChartLine}
                    className="text-white text-3xl text-center mx-auto block"
                  />
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-hovergreen font-bold text-center centurygothic">
                    Strategy & Analytics
                  </h2>
                  <p className="mt-2 text-white text-base domine text-justify">
                    You need a solid strategy backed by data-driven insights to
                    achieve your business goals. Advilite Dubai can help you
                    develop a comprehensive strategy considering your business
                    goals, market trends, and customer needs. We'll conduct a
                    SWOT analysis, create financial projections, and develop
                    KPIs to help you track your progress. We'll also provide
                    ongoing analytics to help you make informed decisions and
                    adjust your strategy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="py-20 bg-opacity-60">
          <div className="container mx-auto block">
            <h2
              data-aos="fade-up"
              className="text-2xl sm:text-2xl lg:text-2xl xl:text-3xl fredoka text-[#0A3242] font-bold py-4 centurygothic w-full text-justify sm:w-[80%] mx-auto block"
            >
              Advilite is a central hub for all the Management Consultant,
              Accounting, and Auditing services you need in Dubai. From Advisory
              Services to Tax Auditing, we offer a wide variety of services to
              an inclusive clientele.
            </h2>
            <Link
              to="/contact-us"
              data-aos="fade-up"
              type="submit"
              className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mx-auto block w-max"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ConsultantServices;