import React, { useState, useEffect } from 'react';
import expertvat from '../img/vataccountingservices/taxhub-Expert-vat-services.webp';
import planningser from '../img/vataccountingservices/taxhub-vat-planning-services.webp';
import registration from '../img/vataccountingservices/taxhub-registration-requirements.webp';
import benefits from '../img/vataccountingservices/taxhub-benefits-of-working.webp';
import vatreturn from '../img/vataccountingservices/taxhub-vat-return-services.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faChartLine, faGears, faGraduationCap, faHandsHelping, faPiggyBank, faUserPlus, faCheck, faStarHalfAlt, faUserCircle, faHourglassHalf, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

const VatAccountingServices = () => {

  const [activeIndex, setActiveIndex] = useState();

    const handleClick = (index) => {
        if (activeIndex === index) {
        setActiveIndex(null);
        } else {
        setActiveIndex(index);
        }
    };

    useEffect( () => {

      $(document).ready(function(){
        $(this).scrollTop(0);
      });

      Aos.init({ duration: 1500 });
  
    },[] );

  return (
    <>
      <Helmet>
        <title>Advilite | Vat Accounting | Value-added tax |VAT in Dubai</title>
        <meta
          name="title"
          content="Advilite | Vat Accounting | Value-added tax |VAT in Dubai"
        />
        <meta
          name="description"
          content="Our Advilite team of VAT specialists in  UAE has extensive expertise and knowledge to ensure that your business stays fully compliant."
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link
          rel="canonical"
          href="https://advilite.com/vat-accounting-services"
        />
        <meta
          property="og:title"
          content="Advilite | Vat Accounting | Value-added tax |VAT in Dubai"
        />
        <meta property="og:site_name" content="advilite.com" />
        <meta
          property="og:url"
          content="https://advilite.com/vat-accounting-services"
        />
        <meta
          property="og:description"
          content="Our Advilite team of VAT specialists in  UAE has extensive expertise and knowledge to ensure that your business stays fully compliant."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://advilite.com/static/media/taxhub-Expert-vat-services.3351cf6d5ffe6fc66222.webp"
        />
      </Helmet>
      <section className="bg-stars bg-black">
        <div className="px-2 bg-blackn bg-opacity-60 h-full py-20">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col md:flex-row justify-center items-center h-full">
              <div className="w-full md:w-1/2">
                <img
                  src={expertvat}
                  alt="advilite-business-consultancy"
                  className="mx-auto block w-3/4 rounded-xl"
                  data-aos="zoom-in-up"
                />
              </div>
              <div className="w-full md:w-1/2">
                <h2
                  data-aos="fade-right"
                  className="text-hovergreen text-2xl sm:text-4xl md:text-3 lg:text-4xl xl:text-5xl font-semibold mb-6 centurygothic mt-8 md:mt-0"
                >
                  Expert VAT Services in Dubai UAE for Your Business
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  As a business owner in Dubai and the UAE, you understand the
                  importance of complying with the region's Value Added Tax
                  (VAT) laws and regulations. However, navigating the complex
                  VAT landscape can be challenging, which is where our firm
                  comes in.
                </p>
                <p
                  data-aos="fade-up"
                  className="text-white mb-8 bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  Our firm offers top-notch VAT services to help your business
                  comply easily with VAT laws and regulations. Our team of
                  experienced VAT professionals has in-depth knowledge of the
                  VAT laws and regulations in Dubai and the UAE, ensuring your
                  business stays fully compliant.
                </p>
                <Link
                  to="/contact-us"
                  className=" rounded-xl text-base py-4 px-4 sm:px-10 text-greenn hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-lightgreen"
                  data-aos="fade-up"
                >
                  File your taxes now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-20 px-2">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blakn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
            VAT Services
          </h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-2 lg:gap-4 xl:gap-6 lg:grid-cols-4">
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                VAT Registration Services
              </h2>
              <p className="mt-2 text-black te xt-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                VAT registration is the first step towards VAT compliance. Our
                team can help your business navigate the VAT registration
                process and ensure all requirements are met. We can also advise
                you on the best VAT registration options for your business.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                VAT Compliance Services
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                VAT laws and regulations can be challenging, especially if you
                are unfamiliar with the legal system. Our team can help you
                understand the requirements and provide guidance on ensuring
                full compliance. We can assist with VAT return filing and
                payment, ensuring your business stays on top of its obligations.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                VAT Advisory Services
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                Our VAT advisory services can help you stay ahead of the curve.
                We provide expert advice on VAT-related matters, including the
                impact of VAT on your business operations, VAT planning and
                optimization, and VAT training for your staff. With our help,
                you can minimize your business's VAT liability and maximize your
                VAT recoveries.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                VAT Recovery Services
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                Our VAT recovery services can help your business recover VAT
                that has been paid but has yet to be claimed. We can review your
                business's VAT records and identify opportunities for VAT
                recovery. Our team can assist with VAT refund applications and
                represent your business in disputes.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greens font-bold py-4 centurygothic w-full md:w-[60%] mx-auto block text-justify">
            Contact us today for detailed information on how our proficient VAT
            services can substantially benefit your business.
          </h2>
          <Link
            to="/contact-us"
            data-aos="flip-down"
            className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mx-auto block w-max"
          >
            Book A Consultant
          </Link>
        </div>
      </section>
      <section className="bg-vatchoosesecond bg-cover bg-no-repeat bg-center ">
        <div className="py-24 px-2">
          <div className="container mx-auto block">
            <h2
              className="text-2xl my-6 sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center"
              data-aos="fade-right"
            >
              Choose Advilite for Exceptional VAT Services in Dubai and UAE
            </h2>
            <p
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
              data-aos="fade-up"
            >
              If you're looking for exceptional Value Added Tax (VAT) services
              in Dubai and the UAE, Advilite is the provider you should choose.
              Advilite has extensive experience and knowledge of local VAT
              regulations and laws, making them the ideal partner for anyone
              seeking reliable and high-quality VAT services.
            </p>
            <p
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
              data-aos="fade-up"
            >
              Working with Advilite ensures that you receive personalized
              attention and guidance every step of the way. Their team of
              experts will help you navigate the complexities of VAT compliance
              and ensure that your business fully complies with all
              requirements.
            </p>
            <p
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
              data-aos="fade-up"
            >
              In addition to their expertise, Advilite offers competitive
              pricing for their services, making them a cost-effective option
              for businesses of all sizes. Whether you are an emerging small
              business or an established large corporation, we can provide
              tailored solutions to your needs. A multinational corporation,
              Advilite can provide tailored solutions to your particular needs.
            </p>
            <p
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
              data-aos="fade-up"
            >
              At Advilite, their commitment to exceptional customer service sets
              them apart from other VAT service providers. They understand the
              importance of delivering prompt and accurate services and work
              tirelessly to ensure their clients are always satisfied.
            </p>
            <p
              className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
              data-aos="fade-up"
            >
              Advilite is ideal for anyone seeking exceptional VAT services in
              Dubai and the UAE. Their expertise, competitive pricing, and
              commitment to customer service make them the go-to provider for
              businesses looking to stay compliant and streamline their VAT
              processes.
            </p>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              Exceptional VAT Services
            </h2>
          </div>
          <div className="container mx-auto block">
            <div className="flex justify-center flex-wrap">
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/2"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Experience and Expertise in VAT Services
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Advilite's team of professionals has extensive experience
                    and expertise in VAT services, assisting numerous businesses
                    in Dubai and the UAE with their VAT compliance requirements.
                    We remain up-to-date with the latest VAT laws and
                    regulations, ensuring that we provide our clients with
                    timely and accurate information to help them stay on top of
                    their VAT compliance.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/2"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Personalized VAT Services
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    We understand that each business is unique, so we take a
                    personalized approach to our VAT services. We collaborate
                    closely with our clients to understand their needs and
                    develop custom solutions. With the aid of our specialized
                    VAT services, We can assist you in smoothly navigating the
                    challenges of VAT compliance.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/2"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Comprehensive VAT Services
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Our VAT services are extensive and cover all aspects of VAT
                    compliance. We provide various VAT services, including
                    registration, compliance, advisory, and recovery. Our
                    comprehensive VAT services guarantee that our clients fully
                    comply with the VAT laws and regulations, avoiding legal or
                    financial complications.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 gradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <h2
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
                data-aos="fade-right"
              >
                VAT Registration, Deregistration, and Amendment: Challenges and
                Requirements
              </h2>
              <p
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                Businesses in the UAE must register for VAT if their annual
                revenue exceeds AED 375,000. However, the UAE VAT law also has
                special provisions for VAT deregistration that mandate
                businesses to deregister if they fail to meet specific criteria.
              </p>
              <p
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                VAT registration, deregistration, and amendment can be
                challenging as businesses must submit accurate and complete
                information and documentation. The FTA requires several
                clarifications and declarations for company deregistration, and
                non-compliance or delay can result in heavy penalties.
              </p>
              <p
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                It's important for businesses to understand VAT registration,
                deregistration, and amendment processes and requirements to
                avoid penalties. Professional assistance can ensure compliance
                and accurate submissions. Our team can provide comprehensive
                support for VAT needs, helping businesses avoid legal or
                financial consequences.
              </p>
              <p
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                data-aos="fade-up"
              >
                Our team of VAT professionals offers comprehensive support for
                businesses in areas such as registration, compliance, advisory,
                and recovery. With our expertise, we can help your business
                navigate the complexities of VAT and succeed in this area of
                taxation.
              </p>
            </div>
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={registration}
                className="mx-auto block"
                alt="advilite why choose"
                data-aos="zoom-in-up"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="px-2">
        <div className="py-24">
          <div className="container mx-auto block">
            <h2
              data-aos="fade-up"
              className="text-2xl my-6 sm:text-2xl lg:text-3xl xl:text-4xl text-blakn font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center"
            >
              Get Reliable VAT Registration Services in Dubai from Our Expert
              Team
            </h2>
            <p
              data-aos="fade-up"
              className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify"
            >
              Are you looking for VAT registration services in Dubai that you
              can rely on? Our team of expert VAT consultants is here to help.
              We provide comprehensive support for the registration process,
              ensuring that you meet all the requirements and remain fully
              compliant with regulations
            </p>
          </div>
        </div>
      </section>
      <section className="py-16 bg-whyvatregister bg-cover bg-no-repeat bg-center px-2">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center">
            Why VAT Registration is Crucial in Dubai
          </h2>
          <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify">
            In Dubai, VAT registration is mandatory for businesses that meet
            specific revenue thresholds. Failure to comply with the regulations
            can lead to severe penalties and legal consequences. Working with
            experienced VAT consultants who can help you navigate the process
            and meet all the requirements is essential.
          </p>
          <div className="grid grid-cols-1 gap-4 md:gap-8 lg:gap-12 sm:grid-cols-2 lg:grid-cols-4 mt-8">
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faGears}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                VAT Registration Services
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                Our firm offers various VAT registration services to help you
                stay compliant. Our services include
              </p>
            </div>
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                VAT Eligibility Assessment
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                Before registering for VAT, it's crucial to determine whether
                your business meets the eligibility criteria. Our team will
                assess your revenue and other factors to determine if you must
                register for VAT
              </p>
            </div>
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faUserPlus}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                VAT Registration Assistance
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                We'll guide you through the entire VAT registration process,
                ensuring you complete all the necessary steps accurately and on
                time.
              </p>
            </div>
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faHandsHelping}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                VAT Compliance Support
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                After registering for VAT, it's essential to maintain compliance
                with all regulations. We'll provide ongoing support to ensure
                you remain compliant, avoiding penalties and legal consequences.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              Common Types of VAT Engagements
            </h2>
            <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify">
              Value, Added Tax (VAT) is a complex area of taxation that can be
              challenging for businesses to manage on their own. That's why many
              companies work with VAT consultants to ensure compliance with
              regulations and maximize their tax savings. Here are some common
              types of VAT engagements that businesses may need:
            </p>
          </div>
          <div className="container mx-auto block mt-4">
            <div className="flex justify-center flex-wrap">
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    VAT Registration Services
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Companies that meet certain revenue thresholds are required
                    to register for VAT. VAT consultants can help companies
                    understand the registration requirements, gather the
                    necessary documentation, and submit the application
                    correctly and on time.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    VAT Compliance Services
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    After a business registers for VAT, it must abide by various
                    regulations, including filing VAT returns, maintaining
                    accurate records and invoices, and collecting and remitting
                    VAT to the government. VAT consultants can assist businesses
                    in adhering to these regulations and avoiding costly
                    penalties.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    VAT Advisory Services
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    VAT consultants can provide strategic advice on how
                    companies can optimize their VAT position. This may include
                    identifying VAT savings opportunities, advising on VAT
                    implications of business transactions, and providing
                    guidance on complex VAT issues.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    VAT Recovery Services
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Businesses that operate in multiple countries may be
                    eligible for VAT recovery on expenses incurred in foreign
                    jurisdictions. VAT consultants can help companies to
                    navigate the complexities of VAT recovery and identify
                    opportunities to recover VAT paid on eligible costs.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    VAT Audit Defense
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    In the event of a VAT audit, businesses may need
                    representation to defend their VAT position. VAT consultants
                    can assist with preparing for the audit, responding to
                    queries from tax authorities, and resolving any issues that
                    arise during the audit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 revgradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={planningser}
                className="mx-auto block"
                alt="advilite why choose"
                data-aos="zoom-in-up"
              />
            </div>
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
              >
                VAT Planning Services
              </h2>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                Our VAT planning services are tailored to meet your needs. We
                can help you develop a customized strategy to manage your VAT
                obligations most efficiently and effectively. We can also keep
                you updated on any changes in the VAT regulations, ensuring that
                you stay on top of your compliance requirements.
              </p>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                At Advilite, we understand that VAT compliance is essential for
                businesses in Dubai. That's why we offer comprehensive VAT
                planning and compliance solutions to help you stay ahead of the
                curve. Our team of experts can provide valuable insights and
                advice on VAT regulations, filing of VAT returns, invoicing,
                record-keeping, and other compliance.
              </p>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                Requirements. With our help, you can ensure you meet all the
                necessary VAT obligations while minimizing the risk of penalties
                and fines. For more information on our VAT compliance solutions,
                contact us today.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
            What is VAT Planning?
          </h2>
          <p className="text-black bitter mt-2 text-base sm:text-lg md:text-lg lg:text-xl domine w-full md:w-[70%] mb-4 mx-auto block text-justify">
            VAT planning is devising a tailored approach to assist businesses in
            better managing their VAT responsibilities. This encompasses
            grasping the VAT guidelines, detecting potential cost-cutting
            opportunities, and establishing optimal practices that guarantee
            regulatory compliance while reducing tax liabilities.
          </p>
          <div className="flex justify-center flex-wrap">
            <div className="p-2 w-full md:w-1/3 lg:w-1/3">
              <div
                className="flex justify-between items-center py-4 px-4 bg-lightgreen cursor-pointer my-2 rounded-xl"
                onClick={() => handleClick(0)}
              >
                <h2 className="centurygothic font-bold md:text-base lg:text-md xl:text-xl">
                  Boost Your VAT Savings with Professional Planning Services
                </h2>
                <svg
                  className={`w-5 h-5 transition-transform ${
                    activeIndex === 0 ? "transform rotate-180" : ""
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div
                className={`${
                  activeIndex === 0 ? "block" : "hidden"
                } py-2 px-4 domine text-base text-justify`}
              >
                If you want to minimize your VAT liabilities and ensure
                compliance with regulations, Advilite has got you covered. Our
                team of proficient experts provides top-notch VAT planning
                services in Dubai. We assist in identifying areas that could
                save you money and create a personalized strategy that makes
                managing your VAT obligations easier.
              </div>
            </div>
            <div className="p-2 w-full md:w-1/3 lg:w-1/3">
              <div>
                <div
                  className="flex justify-between items-center py-4 px-4 bg-lightgreen cursor-pointer my-2 rounded-xl"
                  onClick={() => handleClick(1)}
                >
                  <h2 className="centurygothic font-bold md:text-base lg:text-md xl:text-xl">
                    Comprehensive VAT Planning Services for Businesses of All
                    Sizes
                  </h2>
                  <svg
                    className={`w-5 h-5 transition-transform ${
                      activeIndex === 1 ? "transform rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  className={`${
                    activeIndex === 1 ? "block" : "hidden"
                  } py-2 px-4 domine text-base text-justify`}
                >
                  Advilite is your trusted partner for VAT planning and
                  compliance services in Dubai. With years of experience
                  navigating the intricate world of VAT compliance, our team of
                  professionals ensures that companies of all sizes remain fully
                  compliant while minimizing their tax bills. We have the
                  knowledge, skills, and expertise to help you succeed
                  regardless of the size of your business, whether you're a
                  small startup or a large corporation. Please contact us today,
                  and discover how we can support your VAT planning and
                  compliance requirements. Our team of professionals is always
                  accessible to respond to your inquiries and give you more
                  details about our offerings
                </div>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/3 lg:w-1/3">
              <div>
                <div
                  className="flex justify-between items-center py-4 px-4 bg-lightgreen cursor-pointer my-2 rounded-xl"
                  onClick={() => handleClick(2)}
                >
                  <h2 className="centurygothic font-bold md:text-base lg:text-md xl:text-xl">
                    Effective VAT Planning Strategies for Business Owners
                  </h2>
                  <svg
                    className={`w-5 h-5 transition-transform ${
                      activeIndex === 2 ? "transform rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  className={`${
                    activeIndex === 2 ? "block" : "hidden"
                  } py-2 px-4 domine text-base text-justify`}
                >
                  Managing your VAT obligations can be overwhelming and
                  time-consuming as a business owner. However, with the correct
                  VAT planning strategy, you can streamline the process and
                  minimize your tax liabilities. At Advilite, we understand the
                  importance of VAT planning and offer tailored services to help
                  you manage your VAT obligations efficiently and effectively
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-vatdetails bg-cover bg-no-repeat bg-center ">
        <div className=" py-24 ">
          <div className="container mx-auto block">
            <h2 className="text-2xl my-6 sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-white font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center">
              Our VAT services can assist your business in Dubai and the UAE.
              Contact us today for more details
            </h2>
            <Link
              to="/contact-us"
              className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-greenn mx-auto block w-max"
              data-aos="fade-up"
            >
              Get Started
            </Link>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              The Benefits of VAT Planning
            </h2>
            <p className="text-black bitter mt-2 text-base sm:text-lg md:text-lg lg:text-xl domine w-full md:w-[70%] mb-4 mx-auto block text-center">
              Effective VAT planning can provide a range of benefits for
              businesses, including
            </p>
          </div>
          <div className="container mx-auto block">
            <div className="flex justify-center flex-wrap">
              <div className="p-2 w-full md:w-1/2 lg:w-1/3">
                <div
                  className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
                  data-aos="zoom-in-up"
                >
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Cost Savings
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Businesses can lower their tax liabilities and improve their
                    cash flow by identifying areas where VAT costs can be
                    reduced.
                  </p>
                </div>
              </div>
              <div className="p-2 w-full md:w-1/2 lg:w-1/3">
                <div
                  className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
                  data-aos="zoom-in-up"
                >
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Compliance
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The laws governing VAT can be complicated and dynamic.
                    Proper VAT planning can assist companies in staying current
                    with rules and avoiding expensive fines
                  </p>
                </div>
              </div>
              <div className="p-2 w-full md:w-1/2 lg:w-1/3">
                <div
                  className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
                  data-aos="zoom-in-up"
                >
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Business Efficiency
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    With a streamlined VAT planning strategy, businesses can
                    reduce administrative burdens, improve financial reporting,
                    and focus on core business activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 bg-vatplanning bg-cover bg-no-repeat bg-center px-2">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-semibold centurygothic w-full md:w-3/4 mx-auto block capitalize text-center">
            Why Vat Planning is Essential for Your Business in Dubai
          </h2>
          <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify">
            In Dubai, VAT regulations are vital for businesses, as
            non-compliance can lead to substantial penalties and legal
            repercussions. Therefore, VAT planning is of utmost importance, as
            it aids in reducing tax obligations, uncovering potential savings,
            and enhancing the organization's efficiency.
          </p>
          <div className="grid grid-cols-1 gap-4 md:gap-2 lg:gap-8 md:grid-cols-3 mt-8">
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faBalanceScale}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Minimize Tax Liabilities
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                Proper VAT planning allows you to minimize your tax liabilities
                while ensuring compliance with regulations. This helps you avoid
                overpaying your taxes while staying legally compliant
              </p>
            </div>
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faPiggyBank}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Identify potential savings
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                VAT planning can help you identify areas where you can reduce
                costs and optimize spending. Doing so can free up more resources
                to invest in growth opportunities and stay ahead of the
                competition
              </p>
            </div>
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faChartLine}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Improve Efficiency
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                VAT planning can also improve your company's efficiency. By
                streamlining financial processes and ensuring compliance with
                regulations, you can focus on core business activities and
                achieve your goals faster.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              How Advilite can Assist You With Your VAT Planning Needs
            </h2>
            <p className="text-black bitter mt-2 text-base sm:text-lg md:text-lg lg:text-xl domine w-full md:w-[70%] mb-4 mx-auto block text-center">
              At Advilite, we understand the importance of VAT compliance for
              businesses in Dubai. We offer various VAT planning and compliance
              services to help you manage your obligations more efficiently.
            </p>
          </div>
          <div className="container mx-auto block">
            <div className="flex justify-center flex-wrap">
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/2"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    OUR EXPERTISE
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Our professionals are well-versed in the local VAT
                    legislation and regulations. We stay up-to-date with any
                    changes in the rules, providing accurate and timely advice.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/2"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    VAT PLANNING
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Our VAT planning services are tailored to meet the specific
                    needs of your business. We work with you to develop a
                    customized plan that minimizes your tax liabilities while
                    ensuring compliance with regulations.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/2"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    VAT COMPLIANCE
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    We can help you understand the VAT regulations, file VAT
                    returns, and advise you on invoicing and record-keeping best
                    practices. We ensure that your business remains fully
                    compliant with the rules, reducing the risk of penalties and
                    legal consequences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 gradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
              >
                BENEFITS OF WORKING WITH Advilite
              </h2>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                By working with Advilite for your VAT planning and compliance
                needs, you can:
              </p>
              <ul data-aos="fade-up">
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mx-2 text-hovergreen"
                  />
                  Save time and resources
                </li>
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mx-2 text-hovergreen"
                  />
                  Minimize tax liabilities
                </li>
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mx-2 text-hovergreen"
                  />
                  Avoid penalties and legal consequences
                </li>
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mx-2 text-hovergreen"
                  />
                  Gain valuable insights and advice
                </li>
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mx-2 text-hovergreen"
                  />
                  Focus on core business activities
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={benefits}
                className="mx-auto block"
                alt="advilite why choose"
                data-aos="zoom-in-up"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="w-full md:w-1/2">
              <img
                src={vatreturn}
                alt="advilite-business-consultancy"
                className="mx-auto block rounded-xl"
                data-aos="zoom-in-up"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-2xl xl:text-3xl fredoka text-greenn font-bold py-4 centurygothic"
              >
                WHAT IS VAT RETURNS SERVICES?
              </h2>
              <p
                data-aos="fade-up"
                className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                VAT Returns Services in Dubai UAE refers to the services
                provided by professionals or firms to assist businesses in
                complying with the Value Added Tax (VAT) laws in Dubai, United
                Arab Emirates. These services may include VAT registration,
                filing VAT returns, and ensuring VAT compliance. VAT is a tax on
                the value added to goods and services at each stage of
                production and distribution, and it is a mandatory requirement
                for businesses operating in the UAE to register for and comply
                with VAT regulations. Failure to comply with VAT laws can result
                in penalties and fines.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 bg-whychoosevat bg-cover bg-no-repeat bg-center px-2">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center">
            Why Choose Advilite for Your VAT Return Services?
          </h2>
          <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-justify md:text-center">
            There are several reasons why Advilite is the best choice for your VAT
            return services in Dubai UAE:
          </p>
          <div className="grid grid-cols-1 gap-4 md:gap-8 lg:gap-12 sm:grid-cols-2 lg:grid-cols-4 mt-8">
            <div className="p-4" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faStarHalfAlt}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Expertise and Experience
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                Our team of experts boasts years of experience in the domain of
                VAT compliance and the preparation of tax returns. We stay
                current on the most recent legislation to ensure accuracy and
                effectiveness and use the most cutting-edge tools and
                Technology.
              </p>
            </div>
            <div className="p-4" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faUserCircle}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Personalized Approach
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                Every business is unique, so we offer a personalized approach to
                VAT return services. We'll work closely with you to understand
                your needs and tailor our services accordingly.
              </p>
            </div>
            <div className="p-4" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faHourglassHalf}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Time and Cost Savings
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                By outsourcing your VAT return services to Advilite, you'll save
                valuable time and money to be better spent on growing your
                business.
              </p>
            </div>
            <div className="p-4" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Peace of Mind
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                Our expert services assure you that your VAT returns are
                accurate, compliant, and timely filed. You won't have to worry
                about any penalties or fines.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-16 px-2">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
            Our VAT return services include the following:
          </h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-2 lg:gap-4 xl:gap-6 lg:grid-cols-4">
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                VAT Registration
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                We'll assist you in registering for VAT and obtaining a VAT
                number.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                Data Collection and Analysis:
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                We'll collect all necessary data from your business to ensure
                accuracy and compliance.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                Return Preparation and Filing:
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                We'll prepare and file your VAT returns on your behalf, ensuring
                they're accurate and filed on time.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                VAT Consultancy
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                We'll provide expert consultancy services to help you understand
                the complexities of VAT compliance and make informed decisions
                for your business.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[60%] mx-auto block text-justify">
            Don't struggle with VAT return filing anymore! Advilite's VAT services
            can help your business in Dubai and UAE. Contact us now for details.
          </h2>
          <Link
            to="/contact-us"
            data-aos="fade-up"
            className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mx-auto block w-max"
          >
            Book A Consultant
          </Link>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default VatAccountingServices;