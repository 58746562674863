import React, { useEffect } from 'react';
import imgbc from '../img/advisorybk/taxhub-advisory-bookkeeping.webp';
import whychoose from '../img/advisorybk/taxhub-why-use-taxhub-bookkeeping.webp';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { FaCheck } from 'react-icons/fa';

const UIUXDesignService = () => {

    useEffect(() => {

        Aos.init({ duration: 1500 });

        $(document).ready(function () {
            $(this).scrollTop(0);
        });

    });

    return (
        <>
            <section className="bg-black">
                <div className="px-2 bg-white h-full py-16">
                    <div className="container mx-auto block h-full">
                        <div className="flex flex-col-reverse md:flex-row justify-center items-center h-full">
                            <div className="w-full md:w-1/2">
                                <h2
                                    data-aos="fade-right"
                                    className="text-greenn text-3xl sm:text-4xl md:text-[2.6rem] lg:text-4xl xl:text-5xl font-semibold mb-6 centurygothic mt-8 md:mt-0"
                                >
                                    Elevating User Experiences, Boosting Success
                                </h2>
                                <p
                                    data-aos="fade-up"
                                    className="text-greens bitter mb-8 font-semibold mt-2 text-xl sm:text-lg domine text-justify"
                                >
                                    In today's digital landscape, user experience (UX) and user interface (UI) design are paramount. They're the key to capturing and retaining your audience's attention and driving the success of your digital products. At Advilite, we are passionate about crafting seamless, intuitive, and visually stunning UI/UX experiences, and we're excited to introduce our UI/UX Service.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2">
                                <img
                                    src={imgbc}
                                    alt="advilite-business-consultancy"
                                    className="mx-auto block w-3/4 rounded-xl"
                                    data-aos="zoom-in-up"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-16 gradienthalf">
                <div className="container mx-auto block">
                    <div className="flex flex-col-reverse lg:flex-row items-center">
                        <div className="w-full lg:w-1/2 px-2 lg:px-4">
                            <h2
                                data-aos="fade-down"
                                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
                            >
                                Why Choose Advilite's UI/UX Service?
                            </h2>
                            <ul>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    User-Centric Approach: We put your users at the center of our design process. Understanding their needs, behaviors, and preferences is our first step in creating exceptional experiences.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Strategic UI Design: Our talented UI designers create interfaces that are not just visually appealing but also strategically designed to guide users through your digital products effortlessly.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Intuitive Navigation: We ensure that your users can easily find what they're looking for, resulting in higher engagement and conversions.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Responsive Design: Whether it's a website, mobile app, or software, our designs are responsive and adaptable across all devices, providing a consistent experience.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Prototyping & Testing: We develop interactive prototypes to test and refine our designs, ensuring they meet usability standards and user expectations.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Aesthetic Excellence: Our designers create visually stunning interfaces that enhance your brand's identity, leaving a memorable impression on users.
                                </li>
                            </ul>
                        </div>
                        <div className="w-full lg:w-1/2 px-2 md:px-2">
                            <img
                                src={whychoose}
                                className="mx-auto block"
                                alt="advilite why choose"
                                data-aos="zoom-in-up"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-16 px-2">
                <div className="">
                    <div className="container mx-auto block">
                        <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
                            Why Partner with Advilite?
                        </h2>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            Every stage of the product's development involves a substantial amount of engagement from UI/UX designers. The success of the product is greatly influenced by their efforts to do user research, collect, and communicate that data. They also conduct testing and contribute to future advancements. Everyone cannot complete this process. Handpicked experts that are committed and highly educated are required.
                        </p>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            We are aware of this, which is why we select the best members of our team for this job. Because of this, our staff is the finest at offering you UX services. The same group of people helped us become a recognised leader in digital marketing. So get in touch with us if you want your goods to dominate the markets.
                        </p>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            UI/UX design refers to the design of user interfaces and user experiences for digital products, such as websites, mobile apps, and software applications. The main goal of UI/UX design is to create a seamless and intuitive experience for users, so they can easily accomplish their tasks and goals within the product.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default UIUXDesignService;