import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import { IoMdCall } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const Popup = ({ imageUrl, onClose }) => {

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const phoneNumber = '+971521122777'; // Replace with your phone number
    const whatsappNumber = '+971521122777'; // Replace with your WhatsApp number

    const handleCallClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const handleWhatsAppClick = () => {
        const message = encodeURIComponent("I am interested in your service"); // Encode the message
        window.location.href = `https://wa.me/${whatsappNumber}?text=${message}`;
    };

    return (
        <div className="fixed bg-[#0000007b] inset-0 flex justify-center items-center z-50 p-3 sm:p-0" onClick={handleOverlayClick}>
            <div className="bg-white p-6 rounded shadow-lg relative">
                <button onClick={onClose} className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 cursor-pointer">
                    <AiOutlineCloseCircle className='text-2xl' />
                </button>
                <img src={imageUrl} alt="Popup Image" className="w-[320px] h-[320px] sm:w-[600px] sm:h-[600px] object-contain p-1" />
                <div className='flex float-right gap-2'>
                    <div onClick={handleCallClick} className="bg-blue-500 text-white w-9 h-9 rounded-full flex items-center justify-center cursor-pointer">
                        <IoMdCall className='text-lg mx-auto' />
                    </div>
                    <div onClick={handleWhatsAppClick} className="bg-green-500 text-white w-9 h-9 rounded-full flex items-center justify-center cursor-pointer">
                        <BsWhatsapp className='text-lg mx-auto' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popup;
