import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footern from "./Footer";
import axios from "axios";
// import { useNavigate } from 'react-router-dom';

const DisplayBlogs = () => {
  const [response, setResponse] = useState([]);
  console.log("response", response);

  useEffect(() => {
    fetchfunction();
  }, []);

  const fetchfunction = async () => {
    const res = await axios
      .post("https://api.stashack.cloud:3000/public/view_blog", {
        header: "accept: application/json",
      })
      .then((res) => {
        console.log(res.data.Success);
        setResponse(res.data.Success);
      })
      .catch((err) => console.log("Error: ", err));
    
  };

  const abc = "https://api.earnestroi.com/banner/";

  return (
    <>
      <section className="">
        <div className="container mx-auto ">
          <h2 className="text-second font_primary text-center text-5xl ">
            Advilite Blogs
          </h2>
        </div>
        <div className="my-10 container mx-auto px-2">
          <div className="grid grid-cols1 sm:grid-cols-2 sm:gap-1 md:grid-cols-3 md:gap-4 xl:grid-cols-4 xl:gap-6">
            {response &&
              response.map((resp) => {
                var { FormID, Heading, URL, Banner, Subheading, Layout, Tag } =
                  resp;

                let path = "";
                // if (Layout === "layout_one") {
                //   path = "blog";
                // }
                if (Layout === 1) {
                  path = "blog";
                }
                if (Layout === 2) {
                  path = "blog";
                }
                if (Layout === 3) {
                  path = "blog";
                }
                console.log(resp, "hello");
                console.log(resp.Tag, "tag");
                console.log(resp.FormID, "hghfghf");
                return (
                  <Link
                    to={{
                      pathname: `/${path}/${URL}`,
                    }}
                    state={{ FormID: FormID }}
                    key={FormID}
                  >
                    <div className="px-1">
                      <img
                        src={`https://api.taxhub.ae/banner/${Banner}`}
                        alt={Tag}
                        className="mx-auto block rounded-xl my-1 h-36 w-full"
                      />
                      <h2 className="text-third font_primary text-xl my-1 line-clamp-2">
                        {Heading}
                      </h2>
                      <p className="line-clamp-2 overflow-hidden mt-4">
                        {Subheading}
                      </p>
                      <p className="font_secondary text-base text-right hover:text-greenn mt-2">
                        Read More ...
                      </p>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </section>
      <Footern />
    </>
  );
};

export default DisplayBlogs;
