import React, { useEffect } from 'react';
import imgbc from '../img/advisorybk/taxhub-advisory-bookkeeping.webp';
import whychoose from '../img/advisorybk/taxhub-why-use-taxhub-bookkeeping.webp';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { FaCheck } from 'react-icons/fa';

const GraphicDesigningService = () => {

    useEffect(() => {

        Aos.init({ duration: 1500 });

        $(document).ready(function () {
            $(this).scrollTop(0);
        });

    });

    return (
        <>
            <section className="bg-black">
                <div className="px-2 bg-white h-full py-16">
                    <div className="container mx-auto block h-full">
                        <div className="flex flex-col-reverse md:flex-row justify-center items-center h-full">
                            <div className="w-full md:w-1/2">
                                <h2
                                    data-aos="fade-right"
                                    className="text-greenn text-3xl sm:text-4xl md:text-[2.6rem] lg:text-4xl xl:text-5xl font-semibold mb-6 centurygothic mt-8 md:mt-0"
                                >
                                    Crafting Visual Excellence
                                </h2>
                                <p
                                    data-aos="fade-up"
                                    className="text-greens bitter mb-8 font-semibold mt-2 text-xl sm:text-lg domine text-justify"
                                >
                                    In a world driven by visuals, outstanding graphic design is the cornerstone of effective communication and brand identity. At Advilite, we specialize in harnessing the power of compelling visual content to captivate your audience and leave a lasting impression. Our Graphic Designing Service is your gateway to visually stunning, creative, and impactful designs.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2">
                                <img
                                    src={imgbc}
                                    alt="advilite-business-consultancy"
                                    className="mx-auto block w-3/4 rounded-xl"
                                    data-aos="zoom-in-up"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-16 gradienthalf">
                <div className="container mx-auto block">
                    <div className="flex flex-col-reverse lg:flex-row items-center">
                        <div className="w-full lg:w-1/2 px-2 lg:px-4">
                            <h2
                                data-aos="fade-down"
                                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
                            >
                                Why Choose Advilite's Graphic Designing Service?
                            </h2>
                            <ul>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Creative Brilliance: Our team of skilled graphic designers possesses a flair for creativity and innovation, crafting designs that stand out in a crowded digital landscape.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Customized Solutions: We understand that every business has unique needs and aspirations. Our designs are tailor-made to reflect your brand's personality and vision.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Versatile Portfolio: From logos and branding materials to website graphics, social media content, and marketing collateral, we offer a wide range of design services to meet your requirements.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Attention to Detail: We pay meticulous attention to detail, ensuring that every pixel and element aligns perfectly with your brand's aesthetics and messaging.
                                </li>
                                <li
                                    data-aos="fade-up"
                                    className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-base xl:text-lg domine text-justify flex">
                                    <span className="mr-2 mt-1">
                                        <FaCheck />
                                    </span>
                                    Timely Delivery: We are committed to meeting deadlines and delivering high-quality designs promptly, ensuring that your projects progress seamlessly.
                                </li>
                            </ul>
                        </div>
                        <div className="w-full lg:w-1/2 px-2 md:px-2">
                            <img
                                src={whychoose}
                                className="mx-auto block"
                                alt="advilite why choose"
                                data-aos="zoom-in-up"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-16 px-2">
                <div className="">
                    <div className="container mx-auto block">
                        <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
                            Why Partner with Advilite?
                        </h2>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            Advilite is more than just a graphic designing service; we're your creative partners dedicated to bringing your ideas to life. Our passion for design, commitment to excellence, and dedication to your brand's success set us apart.
                        </p>
                        <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
                            Ready to enhance your brand's visual appeal and make a lasting impression? Contact Advilite today to explore how our Graphic Designing Service can elevate your brand, engage your audience, and set you apart from the competition.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default GraphicDesigningService;